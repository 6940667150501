import React from "react";
import BackBtn from "../components/BackBtn";
import { Container } from "@mui/material";
import imgtele from "../assets/img/teleBg2.png";
const Contact = () => {
  return (
    <>
      <BackBtn />
      <Container>
        <h1 className="my-3 text-center">Contact Us</h1>
        <div className="container teleBg">
          {/* <img
            src={imgtele}
            alt=""
            srcset=""
            style={{ width: "100%", borderRadius: "10px" }}
          /> */}
          <div style={{}}>
            <div
              className="d-flex mx-3 teleimgCon"
              style={{
                
              }}
            >
              <div className="text-light my-3 " style={{ fontSize: "20px" }}>
                Telegram Group
              </div>
              <a
                href="https://t.me/riv_team_contact"
                className="btn btn-primary"
              >
                Contact Us
              </a>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
              <img
                src={imgtele}
                alt=""
                srcset=""
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div className="col-md-6" >
              <div
                className="d-flex mx-3"
                style={{
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                  height: "50vh",
                }}
              >
                <div className="text-light my-3 " style={{ fontSize: "20px" }}>
                  Telegram Group
                </div>
                <a
                  href="https://t.me/+-IgCPKOs8JwyMWQ1"
                  className="btn btn-primary"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </Container>
      {/* <Footer/> */}
    </>
  );
};

export default Contact;
