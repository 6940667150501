import React, { useState, useEffect } from "react";
import { api_url, displayWidth } from "../config";
import axios from "axios";
import BackBtn from "../components/BackBtn";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Spinner from "../components/Spinner";

const Notification = () => {
  const [notifyData, setNotifyData] = useState([""]);
  const [loading, setLoading] = useState(false);

  async function fetchNotifyData() {
    try {
      setLoading(true);
      const tokenGet = await localStorage.getItem("logintoken");
      const response = await axios.get(`${api_url}/notification`, {
        headers: {
          Authorization: `Bearer ${tokenGet}`,
        },
      });
      console.log("notify", response.data);
      setNotifyData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  }
  useEffect(() => {
    fetchNotifyData();
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      {loading ? <Spinner loading={loading} /> : null}
      <BackBtn title={"Notification"} />
      <Container
        sx={{ width: displayWidth, height: "99vh", paddingTop: "55px" }}
      >
        {notifyData.length === 0 ? (
          notifyData.map((item) => {
            const inputDate = item.created_at;

            const date = new Date(inputDate);
            const day = date.getDate();
            const month = date.getMonth() + 1; // Months are zero-indexed
            const year = date.getFullYear();
            const options = {
              hour: "2-digit",
              minute: "2-digit",
            };

            const formattedTime = date.toLocaleTimeString("en-US", options);

            // const formattedDate = date.toLocaleString("en-US", options);
            const formattedDate = `${day}-${month}-${year} , ${formattedTime} `;

            return (
              <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Item sx={{ padding: "0px" }}>
                      <div
                        style={{ background: "#7275ff" }}
                        className="text-light py-2"
                      >
                        {item.title}
                      </div>
                      <p className="py-2">{item.message}</p>
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-between",
                          margin: "20px",
                          paddingBottom: "10px",
                        }}
                      >
                        <div className="bold">Team RIV</div>
                        <div className="bold">{formattedDate}</div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            );
          })
        ) : (
          <div>
            <img style={{width:"100%"}} src={require("../assets/img/nodata.png")} alt="" />
          </div>
        )}
      </Container>
    </>
  );
};

export default Notification;
