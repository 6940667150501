import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { api_url } from "../../config";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { GoDotFill } from "react-icons/go";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import noDataImg2 from "../../assets/img/illustration/nodata2.png";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

// const rows = [
//   createData('Cupcake', 305, 3.7),
//   createData('Donut', 452, 25.0),
//   createData('Eclair', 262, 16.0),
//   createData('Frozen yoghurt', 159, 6.0),
//   createData('Gingerbread', 356, 16.0),
//   createData('Honeycomb', 408, 3.2),
//   createData('Ice cream sandwich', 237, 9.0),
//   createData('Jelly Bean', 375, 0.0),
//   createData('KitKat', 518, 26.0),
//   createData('Lollipop', 392, 0.2),
//   createData('Marshmallow', 318, 0),
//   createData('Nougat', 360, 19.0),
//   createData('Oreo', 437, 18.0),
// ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const Winner = (props) => {
  // const [rows, setRows] = useState([props.data]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [expanded, setExpanded] = React.useState(false);
  const [feesGet, setFeesGet] = useState("");

  const rows = props.data;

  // useEffect(() => {
  //   console.log("ga", props.data);
  // }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const getWinnerData = async () => {
  //   const loginToken = localStorage.getItem("logintoken");
  //   try {
  //     // setLoading(true);
  //     const response = await axios.get(`${api_url}/transaction/winners`, {
  //       headers: {
  //         Authorization: `Bearer ${loginToken}`,
  //       },
  //     });
  //     console.log("res-win-data", response.data);
  //     setRows(response?.data?.data);
  //     // setLoading(false);
  //   } catch (error) {
  //     // setLoading(false);
  //     if (error.response) {
  //       console.error("Server Error:", error.response.data);
  //       // setUserDetails(error.response.data);
  //     } else if (error.request) {
  //       console.error("No response received.");
  //     } else {
  //       console.error("Request failed:", error.message);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   getWinnerData();
  // }, []);

  return (
    <TableContainer component={Paper} sx={{ marginTop: "20px" , backgroundColor:"#333" }}>
      {rows.length === 0 ? (
        <TableRow >
          <TableCell   sx={{backgroundColor:"#333!important"}} colSpan={4} align="center">
            <img style={{height:"300px" , backgroundColor:"#333"}} src={noDataImg2} alt="" />
          </TableCell>
        </TableRow>
      ) : (
        <Table sx={{ width: "100%" }} aria-label="custom pagination table">
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => {
              const inputDate = row.created_at;
              // console.log('inputDate' , inputDate)
              const date = new Date(inputDate);
              const day = date.getDate();
              const month = date.getMonth() + 1; // Months are zero-indexed
              const year = date.getFullYear();
              const options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              };

              const formattedTime = date.toLocaleTimeString("en-US", options);

              const formattedDate = date.toLocaleString("en-US", options);
              // const formattedDate = `${day}-${month}-${year} , ${formattedTime} `;

              // if (row.number === 0) {
              //   const totalFees = (row.amount * 2) / 20;
              //   setFeesGet(totalFees);
              // } else {
              //   const totalFees = (row.amount * 10) / 20;
              //   setFeesGet(totalFees);
              // }

              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" sx={{ padding: "0px" }}>
                    <Box>
                      <Accordion
                        expanded={expanded === `${row.id}`}
                        onChange={handleChange(`${row.id}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{
                              width: "33%",
                              flexShrink: 0,
                              textAlign: "left",
                            }}
                          >
                            {row.bid_no}
                          </Typography>
                          <Typography
                            sx={{
                              color: row.status === 1 ? "green" : "red",
                              width: "33%",
                              textAlign: "right",
                            }}
                          >
                            {row.status === 1 ? "Success" : "Failure"}
                          </Typography>
                          <Typography
                            sx={{
                              width: "33%",
                              color: row.status === 1 ? "green" : "red",
                              textAlign: "right",
                            }}
                          >
                            {row.status === 1
                              ? `+${row.payble_amount}`
                              : `-${row.payble_amount}`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div>Period No.</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>{row.bid_no}</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>Contract amount</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>{row.amount}</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>Received amount</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div
                                  style={{
                                    color: row.status === 1 ? "green" : "red",
                                  }}
                                >
                                  {row.status === 1
                                    ? `+${row.payble_amount}`
                                    : `-${row.payble_amount}`}
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>Fees</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>{row.fee}</div>
                                {/* <div>
                                {row.number === 0
                                  ? (row.amount * 2) / 20
                                  : (row.amount * 10) / 20}
                              </div> */}
                              </Grid>
                              <Grid item xs={6}>
                                <div>Select</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div
                                  style={{
                                    color:
                                      row.color === 3
                                        ? "green"
                                        : row.color === 2
                                        ? "violet"
                                        : row.color === 1
                                        ? "red"
                                        : "blue",
                                  }}
                                >
                                  {row.color === 3 ? (
                                    <GoDotFill size={23} color="green" />
                                  ) : row.color === 2 ? (
                                    <GoDotFill size={23} color="violet" />
                                  ) : row.color === 1 ? (
                                    <GoDotFill size={23} color="red" />
                                  ) : (
                                    row.number
                                  )}
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>Status</div>
                              </Grid>
                              <Grid item xs={6}>
                                <div
                                  style={{
                                    color: row.status === 1 ? "green" : "red",
                                  }}
                                >
                                  {row.status === 1 ? "Success" : "Failure"}
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div>Date & Time</div>
                              </Grid>
                              <Grid item xs={6}>
                                {/* <div>{row.created_at.toLocaleString("en-US")}</div> */}
                                <div>{formattedDate}</div>
                              </Grid>
                            </Grid>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </TableContainer>
  );
};

export default Winner;
