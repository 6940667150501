import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Gift from "../screen/Gift";
import Login from "../screen/Login";
import Profile from "../screen/Profile";
import Home from "../screen/Home";
import { FaHome } from "react-icons/fa";

function refreshMessages() {
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

  return Array.from(new Array(50)).map(
    () => messageExamples[getRandomInt(messageExamples.length)]
  );
}

const messageExamples = [
  {
    primary: "Brunch this week?",
    secondary:
      "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: "/static/images/avatar/5.jpg",
  },
  {
    primary: "Birthday Gift",
    secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
    person: "/static/images/avatar/1.jpg",
  },
  {
    primary: "Recipe to try",
    secondary:
      "I am try out this new BBQ recipe, I think this might be amazing",
    person: "/static/images/avatar/2.jpg",
  },
  {
    primary: "Yes!",
    secondary: "I have the tickets to the ReactConf for this year.",
    person: "/static/images/avatar/3.jpg",
  },
  {
    primary: "Doctor's Appointment",
    secondary:
      "My appointment for the doctor was rescheduled for next Saturday.",
    person: "/static/images/avatar/4.jpg",
  },
  {
    primary: "Discussion",
    secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
    person: "/static/images/avatar/5.jpg",
  },
  {
    primary: "Summer BBQ",
    secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
    person: "/static/images/avatar/1.jpg",
  },
];

const Footer = () => {
  const [isTabletView, setIsTabletView] = useState(window.innerWidth <= 768);
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const [messages, setMessages] = React.useState(() => refreshMessages());

  // React.useEffect(() => {
  //   // ref.current.ownerDocument.body.scrollTop = 0;
  //   setMessages(refreshMessages());
  // }, [value, setMessages]);

  console.log(value, "vl");

  useEffect(() => {
    function handleResize() {
      setIsTabletView(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "gray",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <div>
      {/* {isTabletView ? ( */}
      <Box sx={{ pb: 7, zIndex: "10" }} ref={ref}>
        <CssBaseline />
        {/* <List>
            {messages.map(({ primary, secondary, person }, index) => (
              <ListItem button key={index + person}>
                <ListItemAvatar>
                  <Avatar alt="Profile Picture" src={person} />
                </ListItemAvatar>
                <ListItemText primary={primary} secondary={secondary} />
              </ListItem>
            ))}
          </List> */}
        {/* {value === 1 && <Gift/>} */}
        {value === 0 && <h1 className="container p-4">home</h1>}
        {value === 1 && <Gift />}
        {value === 2 && <Profile />}
        {/* {value === 1 && <Login/>} */}
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction label="Home" icon={<FaHome />} />
            <BottomNavigationAction label="Gift" icon={<CardGiftcardIcon />} />
            <BottomNavigationAction
              label="Profile"
              icon={<AccountCircleOutlinedIcon />}
            />
          </BottomNavigation>
        </Paper>
      </Box>
      {/* // ) : (
      //   <footer className="bg-primary text-center text-white">
      //     <div className="container p-4"></div>
      //     <div
      //       className="text-center p-3 bg-info"
      //       // style="background-color: rgba(0, 0, 0, 0.2)"
      //     >
      //       © 2020 Copyright:
      //       <a className="text-white" href="#">
      //         riv.com
      //       </a>
      //     </div>
      //   </footer>
      // )} */}
    </div>
  );
};

export default Footer;
