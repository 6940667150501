import React from "react";
import logo from "../assets/img/logo/riv_logo.png";
import { Link } from "react-router-dom";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { displayWidth, primaryBoxBgColor } from "../config";

const Header = () => {
  return (
    <>
    <nav className="navbar text-light bg-dark" style={{position:"fixed" , top:0, zIndex:"1030" , width:displayWidth}}>
        <div className="container-fluid" style={{backgroundColor:"#333!important"}}>
          <Link className="navbar-brand" to="/">
            <img
              style={{ borderRadius: "10px" }}
              src={logo}
              alt="Logo"
              width="70"
              height="74"
              className="d-inline-block align-text-top"
            />
          </Link>
          <a className="navbar-brand btn downloadBtn" style={{background:primaryBoxBgColor}} href="#" download>
            <DownloadForOfflineOutlinedIcon size={18} /> <span>Download App</span> 
          </a>
        </div>
      </nav>
    </>
  );
};

export default Header;
