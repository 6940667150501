import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { FaRegBell, FaBell, FaRupeeSign } from "react-icons/fa";
import Badge from "@mui/material/Badge";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../components/FooterTwo";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { showUserDetail } from "../redux/stateSlice/authSlice";
import axios from "axios";
import {
  api_url,
  primaryBoxBgColor,
  primaryColor,
  primaryColor2,
} from "../config";
import Spinner from "../components/Spinner";
import Grid from "@mui/material/Grid";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Wallet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loadingBar } = useSelector((state) => state.user);
  const [progress, setProgress] = React.useState(90);

  return (
    <>
      {loadingBar ? <Spinner loading={loadingBar} /> : null}
      <Header />
      <div
        style={{ background: primaryBoxBgColor, borderRadius: "20px" , marginTop:"100px"}}
        className="py-3"
      >
        <div className="container">
          <div
            style={{
              display: "flex",
              color: primaryColor2,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <AccountBalanceWalletOutlinedIcon />
            <div
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                color: primaryColor2,
              }}
            >
              ₹{user?.data?.wallet}.00
            </div>
            <div style={{ fontSize: "20px", color: primaryColor2 }}>
              Total Balance
            </div>
          </div>
        </div>
      </div>

      <div
        className=" p-2 m-2 "
        style={{
          background: "#3f3f3f",
          borderRadius: "10px",
          color: primaryColor,
        }}
      >
        <div className="d-flex" style={{ justifyContent: "space-around" }}>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              flexDirection: "column",
            }}
          >
            <CircularProgressWithLabel value={progress} />
            <div>₹0.00</div>
            <div>Main Balance</div>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              flexDirection: "column",
            }}
          >
            <CircularProgressWithLabel value={30} />
            <div>₹0.00</div>
            <div>3rd party Balance</div>
          </div>
        </div>
        <div
          style={{
            background: primaryBoxBgColor,
            padding: "10px",
            textAlign: "center",
            margin: "10px",
            borderRadius: "10px",color:primaryColor2 , fontWeight:"bold"
          }}
        >
          Main wallet transfer
        </div>
        <div className="d-flex" style={{ justifyContent: "space-around" }}>
          <div className="d-flex" style={{ flexDirection: "column" }} onClick={() => navigate("/wallet")}>
            <img
              src={require("../assets/img/icons/wallet.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            Wallet
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }} onClick={() => navigate("/deposit")}>
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            Deposit
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }} onClick={() => navigate("/withdrawal")}>
            <img
              src={require("../assets/img/icons/withdraw.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            Withdraw
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/vip.png")}
              alt=""
              style={{ width: "40px" }}
            />
            VIP
          </div>
        </div>
      </div>
      <Grid container spacing={1} sx={{ marginTop: "1px", padding: "10px" }}>
        <Grid item xs={4}>
          <div className="transCon2" style={{ background: "#3F3F3F" , justifyContent:"center" }}>
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                ₹0.00
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Lottery
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="transCon2" style={{ background: "#3F3F3F" , justifyContent:"center" }}>
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                ₹0.00
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Evo video
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="transCon2" style={{ background: "#3F3F3F" , justifyContent:"center" }}>
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                ₹0.00
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Jili
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="transCon2" style={{ background: "#3F3F3F" , justifyContent:"center" }}>
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                ₹0.00
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                JDB
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="transCon2" style={{ background: "#3F3F3F" , justifyContent:"center" }}>
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                ₹0.00
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                PG
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <FooterTwo value={3} />
    </>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        style={{ color: primaryColor, width: "100px", height: "100px" }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color={primaryColor}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default Wallet;
