import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { FaRegBell, FaBell, FaRupeeSign } from "react-icons/fa";
import Badge from "@mui/material/Badge";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../components/FooterTwo";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { showUserDetail } from "../redux/stateSlice/authSlice";
import axios from "axios";
import {
  api_url,
  loginToken,
  primaryBoxBgColor,
  primaryColor,
  primaryColor2,
} from "../config";
import Spinner from "../components/Spinner";
import Grid from "@mui/material/Grid";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BackBtn from "../components/BackBtn";
import { CiWallet } from "react-icons/ci";
import CachedIcon from "@mui/icons-material/Cached";
import { IoStarOutline } from "react-icons/io5";
import { toast } from "react-toastify";

const Deposit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loadingBar } = useSelector((state) => state.user);
  const [progress, setProgress] = React.useState(90);
  const [userData, setUserData] = useState({
    amount: "",
    reference_no: "",
    pay_through: "upi",
    account_id: "38495834",
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleRechargeRequest = async () => {
    if (userData.amount === "" || userData.reference_no === "") {
      toast.error("Fill in all the fields!");
      return false;
    }
    try {
      console.log("userdata", userData);
      const response = await axios.post(
        `${api_url}/request/recharge`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );
      console.log(response.data, "res");
      // setUserDetails(response.data);
      if (response.data.success === true) {
        toast.success(response.data.message);
        setUserData({
          amount: "",
          reference_no: "",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  return (
    <>
      {loadingBar ? <Spinner loading={loadingBar} /> : null}
      <BackBtn
        title={"Deposit"}
        rightNavigate={"/recharge_record"}
        rightText={"Deposit history"}
      />
      <Box
        sx={{
          flexGrow: 1,
          marginBottom: "10px",
          margin: "0px 10px",
          background: primaryBoxBgColor,
          padding: "17px!important",
          borderRadius: "10px",
          marginTop: "70px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="d-flex" style={{ flexDirection: "row" }}>
              <div style={{ marginTop: "7px", marginRight: "10px" }}>
                <CiWallet size={35} color={primaryColor2} />
              </div>
              <div>
                <div
                  className=""
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: primaryColor2,
                  }}
                >
                  ₹{user?.data?.wallet}.00
                  <span>
                    {" "}
                    <CachedIcon size={20} />
                  </span>
                </div>
                <label
                  className="text-center"
                  htmlFor="rupee"
                  style={{ color: primaryColor2, marginBottom: "10px" }}
                >
                  Available Balance
                </label>
              </div>
            </div>
            <div className="d-flex" style={{ justifyContent: "space-around" }}>
              <img
                style={{ width: "48px", height: "34px" }}
                src={require("../assets/img/cardBg.jpeg")}
                alt=""
              />
              <div style={{ color: primaryColor2 }}>*******</div>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: "1px", padding: "10px" }}>
        <Grid item xs={4}>
          <div
            className=""
            style={{ background: "#3F3F3F", justifyContent: "center" }}
          >
            <div
              className="d-flex"
              style={{ alignItems: "center", flexDirection: "column" }}
            >
              <img
                style={{ width: "-webkit-fill-available" }}
                src={require("../assets/img/icons/UPI.jpg")}
                alt=""
              />
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                UPI
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            className=""
            style={{ background: "#3F3F3F", justifyContent: "center" }}
          >
            <div
              className="d-flex"
              style={{ alignItems: "center", flexDirection: "column" }}
            >
              <img
                style={{ width: "88px" }}
                src={require("../assets/img/icons/trx.png")}
                alt=""
              />
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                TRX
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            className=""
            style={{ background: "#3F3F3F", justifyContent: "center" }}
          >
            <div
              className="d-flex"
              style={{ alignItems: "center", flexDirection: "column" }}
            >
              <img
                style={{ width: "88px" }}
                src={require("../assets/img/icons/usdt.png")}
                alt=""
              />
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                USDT
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="card m-2" style={{ background: "#333" }}>
        <div class="card-body">
          <h5 class="card-title text-light">Deposit Amount</h5>
          <hr className="text-light" />
          <div class="input-group mb-3 bg-dark">
            <span
              class="input-group-text text-light"
              id="basic-addon1"
              style={{ background: "#333" }}
            >
              ₹
            </span>
            <input
              type="number"
              class="form-control text-light light-placeholder"
              placeholder="Amount"
              name="amount"
              id="amount"
              aria-label="Amount"
              aria-describedby="basic-addon1"
              style={{ background: "#333", color: "white" }}
              value={userData.amount}
              onChange={handleChange}
            />
          </div>
          <div class="input-group mb-3 bg-dark">
            <span
              class="input-group-text text-light"
              id="basic-addon2"
              style={{ background: "#333" }}
            >
              ₹
            </span>
            <input
              type="text"
              class="form-control text-light light-placeholder"
              placeholder="Reference No./UTR No."
              aria-label="Reference No./UTR No."
              aria-describedby="basic-addon2"
              style={{ background: "#333", color: "white" }}
              id="reference_no"
              name="reference_no"
              value={userData.reference_no}
              onChange={handleChange}
            />
          </div>
          <button onClick={handleRechargeRequest} class="btn" style={{ background: primaryBoxBgColor }}>
            Deposit
          </button>
        </div>
      </div>

      <div className="card m-2" style={{ background: "#333" }}>
        <div class="card-body">
          <h5 class="card-title text-light">Recharge instrunction</h5>
          <hr className="text-light" />
          <ul className="text-light" style={{}}>
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem
              vitae cumque voluptates possimus! Id vel esse eaque, vitae et
              ipsum.
            </li>
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem
              vitae cumque voluptates possimus! Id vel esse eaque, vitae et
              ipsum.
            </li>
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem
              vitae cumque voluptates possimus! Id vel esse eaque, vitae et
              ipsum.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Deposit;
