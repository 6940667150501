import React, { Fragment, Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  displayWidth,
  primaryBoxBgColor,
  primaryColor,
  primaryColor2,
  theme,
} from "../config";
import { ThemeProvider } from "@mui/material/styles";
import Parity from "./giftscreen/Parity";
import PriceModal from "../components/PriceModal";
import Header from "../components/Header";
import FooterTwo from "../components/FooterTwo";
import { useDispatch, useSelector } from "react-redux";
import { showUserDetail } from "../redux/stateSlice/authSlice";
import Grid from "@mui/material/Grid";
import { CiWallet } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import CachedIcon from "@mui/icons-material/Cached";
import OneMinGame from "./giftscreen/OneMinGame";
import ThreeMinGame from "./giftscreen/ThreeMinGame";
import FiveMinGame from "./giftscreen/FiveMinGame";
import TenMinGame from "./giftscreen/TenMinGame";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Gift = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const { user, loadingBar } = useSelector((state) => state.user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(showUserDetail());
  }, []);

  return (
    <>
      {loadingBar ? <Spinner loading={loadingBar} /> : null}
      <Fragment>
        <Suspense>
          <ThemeProvider theme={theme}>
            <Header />
            <div className="" style={{ paddingTop: "15px" , width:displayWidth , marginTop:"100px" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  marginBottom: "10px",
                  margin: "0px 10px",
                  background: primaryBoxBgColor,
                  padding: "17px!important",
                  borderRadius: "10px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="d-flex" style={{ flexDirection: "row" }}>
                      <div style={{ marginTop: "7px", marginRight: "10px" }}>
                        <CiWallet size={35} color={primaryColor2} />
                      </div>
                      <div>
                        <div
                          className=""
                          style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: primaryColor2,
                          }}
                        >
                          ₹{user?.data?.wallet}.00
                          {/* ₹0.00{" "} */}
                          <span>
                            {" "}
                            <CachedIcon size={20} />
                          </span>
                        </div>
                        <label
                          className="text-center"
                          htmlFor="rupee"
                          style={{ color: primaryColor2, marginBottom: "10px" }}
                        >
                          Available Balance
                        </label>
                      </div>
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-around" }}
                    >
                      <button onClick={()=> navigate('/deposit')}
                        className="btn"
                        style={{
                          background: primaryColor2,
                          color: primaryColor,
                        }}
                      >
                        Deposite
                      </button>
                      <button onClick={()=> navigate('/withdrawal')}
                        className="btn"
                        style={{
                          background: primaryColor,
                          color: primaryColor2,
                          border: `1px solid ${primaryColor2}`,
                        }}
                      >
                        Withdraw
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  paddingLeft: "10px!important",
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    indicatorColor="main_color"
                    sx={{
                      "& .MuiTabs-indicator": {
                        display: "none",
                      },
                      "& .Mui-selected": {
                        color: "#333!important",
                        background: primaryBoxBgColor,
                        borderRadius: "10px",
                      },
                      "& .MuiTab-root": {
                        color: "gray",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <Tab
                      sx={{
                        fontSize: "13px",
                        width: "40px",
                      }}
                      label="Win Go 1Min"
                      icon={
                        value === 0 ? (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch1.png")}
                          />
                        ) : (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch2.png")}
                          />
                        )
                      }
                      {...a11yProps(0)}
                    />

                    <Tab
                      sx={{
                        fontSize: "13px",
                        width: "40px",
                      }}
                      label="Win Go 3Min"
                      icon={
                        value === 1 ? (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch1.png")}
                          />
                        ) : (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch2.png")}
                          />
                        )
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{
                        fontSize: "13px",
                        width: "40px",
                      }}
                      label="Win Go 5Min"
                      icon={
                        value === 2 ? (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch1.png")}
                          />
                        ) : (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch2.png")}
                          />
                        )
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{
                        fontSize: "13px",
                        width: "40px",
                      }}
                      label="Win Go 10Min"
                      icon={
                        value === 3 ? (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch1.png")}
                          />
                        ) : (
                          <img
                            className="tabImg"
                            src={require("../assets/img/icons/watch2.png")}
                          />
                        )
                      }
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <OneMinGame />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <ThreeMinGame/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <FiveMinGame/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <TenMinGame/>
                </CustomTabPanel>
              </Box>
            </div>

            <FooterTwo value={1} />
            {/* <FooterBottomOne /> */}
          </ThemeProvider>
        </Suspense>
      </Fragment>
    </>
  );
};

export default Gift;
