import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { showUserDetail } from "../redux/stateSlice/authSlice";
import FooterTwo from "../components/FooterTwo";
import { api_url, primaryBoxBgColor, primaryColor } from "../config";
import axios from "axios";
import { Container } from "@mui/material";
// import balanceImg from "../assets/img/balance_bg.jpg";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import RedeemIcon from "@mui/icons-material/Redeem";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Activity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loadingBar } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [getImages, setGetImages] = useState([""]);
  const [loginCheck, setLoginCheck] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the active index to move to the next slide
      setActiveIndex((prevIndex) => (prevIndex + 1) % getImages.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [activeIndex, getImages.length]);

  useEffect(() => {
    const checkTokenAndNavigate = async () => {
      try {
        const tokenGet = await localStorage.getItem("logintoken");
        console.log(tokenGet, "logingtok splash");
        // dispatch(showUserDetail(tokenGet));
        if (tokenGet != null) {
          setLoginCheck(false);
          // props.navigation.navigate('DrawerNavigation');
        } else {
          setLoginCheck(true);
          // props.navigation.navigate('Login');
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    };
    checkTokenAndNavigate();
  }, []);

  useEffect(() => {
    dispatch(showUserDetail());
  }, []);

  const fetchImages = async () => {
    axios
      .get(`${api_url}/banners`)
      .then((res) => {
        setLoading(true);
        console.log("res-banners", res.data);
        setGetImages(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error-banners", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      {loadingBar ? <Spinner loading={loadingBar} /> : null}
      <Header />
      <Box sx={{marginTop:"115px"}}>
        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item xs={12} sx={{}}>
            <div
              style={{
                cursor: "pointer",
                // background: primaryBoxBgColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                color: primaryColor,
                textAlign: "center",
              }}
            >
              <div className="actCon" onClick={()=> navigate("/coming_soon")}>
                <RedeemIcon />
                <p>Activity Award</p>
              </div>
              <div className="actCon" onClick={()=> navigate("/coming_soon")}>
                <PaidOutlinedIcon />
                <p>Betting rebate</p>
              </div>
              <div className="actCon" onClick={()=> navigate("/coming_soon")}>
                <SavingsOutlinedIcon />
                <p>Casino</p>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px", padding: "10px" }}>
          <Grid item xs={6} sx={{}}>
            <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={()=> navigate("/coming_soon")}>
                <CardMedia
                  component="img"
                  height="140"
                  image={require("../assets/img/giftRedeem2.png")}
                  alt="gift"
                />
                <CardContent sx={{ background: "#3F3F3F" }}>
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ color: primaryColor, fontWeight: "bold" }}
                  >
                    Gift
                  </Typography>
                  <Typography variant="body2" color="rgb(182 182 182 / 87%)">
                    Enter Lorem ipsum dolor sit amet.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{}}>
            <Card sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={()=> navigate("/coming_soon")}>
                <CardMedia
                  component="img"
                  height="140"
                  image={require("../assets/img/giftRedeem1.png")}
                  alt="bonus"
                />
                <CardContent sx={{ background: "#3F3F3F" }}>
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ color: primaryColor, fontWeight: "bold" }}
                  >
                    Attendance bonus
                  </Typography>
                  <Typography variant="body2" color="rgb(182 182 182 / 87%)">
                    Enter Lorem ipsum dolor sit amet.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sx={{}}>
            <Card sx={{}}>
            <CardActionArea onClick={()=> navigate("/first_deposit")}>
                <CardMedia
                  component="img"
                  height="140"
                  image={require("../assets/img/banner-4.png")}
                  alt=""
                />
                <CardContent sx={{ background: "#3F3F3F" }}>
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ color: primaryColor, fontWeight: "bold" }}
                  >
                    Member's first depotis bonus
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sx={{}}>
            <Card sx={{}} >
              <CardActionArea onClick={()=> navigate("/coming_soon")}>
                <CardMedia
                  component="img"
                  height="140"
                  image={require("../assets/img/banner-5.png")}
                  alt="bonus"
                />
                <CardContent sx={{ background: "#3F3F3F" }}>
                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ color: primaryColor, fontWeight: "bold" }}
                  >
                    Attendance bonus
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <FooterTwo value={1} />
    </>
  );
};

export default Activity;
