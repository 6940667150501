import React, { useState, CSSProperties, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { displayWidth } from "../config";

// const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

const Spinner = (props) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    setLoading(props.loading);
  }, []);

  return (
    <div className="sweet-loading">
      <div
        style={{
          display: "flex",
          position: "absolute",
          zIndex: 1,
          height: "100%",
          width: displayWidth,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,.3)",
        }}
      >
        <ClipLoader
          color={"white"}
          loading={loading}
          cssOverride={{
            display: "block",
            margin: "0 auto",
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Spinner;
