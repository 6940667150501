import React, { useState } from "react";
import "../assets/css/login.css";
import Header from "../components/Header";
import loginBg from "../assets/img/illustration/1.png";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Checkbox from "@mui/material/Checkbox";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import BackBtn from "../components/BackBtn";
import signupLogo from "../assets/img/signupImg.png";
import { ToastContainer, toast } from "react-toastify";
import {
  api_url,
  displayWidth,
  primaryBoxBgColor,
  primaryColor,
} from "../config";
import axios from "axios";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";

const SignUp = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref_code = searchParams.get("ref_code");
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    referral_code: ref_code || "",
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
    // console.log(value);

    // if (fieldName === 'phone') {
    //   const remainingDigits = 10 - value.length;
    //   if (value.length === 10 && /^\d+$/.test(value)) {
    //     // setBtnDisable(true)
    //     setPhoneHelpText('');
    //   } else {
    //     // setBtnDisable(false)
    //     setPhoneHelpText(`Remaining digits: ${remainingDigits}`);
    //   }
    // } else if (fieldName === 'email') {
    //   // Basic email validation using a regular expression
    //   if (/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value)) {
    //     setEmailHelpText('');
    //     // setBtnDisable(false)
    //   } else {
    //     setEmailHelpText('Please enter a valid email address');
    //     // setBtnDisable(true)
    //   }
    // }
    // const isPhoneValid = userData.phone.length === 10;
    // const isEmailValid = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(
    //   userData.email,
    // );
    // setBtnDisable(!(isPhoneValid && isEmailValid));
  };

  const handleRegister = async () => {
    if (
      userData.email === "" ||
      userData.name === "" ||
      userData.password === "" ||
      userData.phone === ""
    ) {
      console.log("first");
      setBtnLoading(false);
      notifyErr("Please fill all required fields!");
      return false;
    }
    try {
      setBtnLoading(true);
      const response = await axios.post(`${api_url}/auth/signup`, userData);
      console.log(response.data, "res");
      setBtnLoading(false);
      // setUserDetails(response.data);
      if (response.data.success === true) {
        notifySuccess(response.data.message);
        navigate("/signin");
      }
    } catch (error) {
      setBtnLoading(false);
      if (error.response) {
        notifyErr(error.response.data.message);
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {/* <Header /> */}
      <BackBtn title={"Sign Up"} />
      <ToastContainer />
      <div
        className="container "
        style={{ marginTop: "100px", height: "85vh" }}
      >
        <div className="row justify-content-center">
          <div className="">
            <div className="" style={{ width: displayWidth }}>
              <div className="text-center" style={{}}>
                <Link to="/">
                  <img
                    src={require("../assets/img/logo/riv_logo.png")}
                    alt="signin-image"
                    style={{ height: "160px", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div className="w-100 p-4 p-md-5">
                <form action="#" className="signin-form">
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      class="form-control text-light customInput"
                      id="name"
                      placeholder="Name"
                      value={userData.name}
                      onChange={handleChange}
                      style={{ background: "#333", color: "white" }}
                    />
                    {/* <TextField
                      sx={{ width: "100%" }}
                      id="name"
                      label="Name"
                      value={userData.name}
                      onChange={handleChange}
                      variant="outlined"
                    /> */}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      class="form-control text-light customInput"
                      id="email"
                      type="email"
                      value={userData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      style={{ background: "#333", color: "white" }}
                    />
                    {/* <TextField
                      sx={{ width: "100%" }}
                      id="email"
                      type="email"
                      value={userData.email}
                      onChange={handleChange}
                      label="Email"
                      variant="outlined"
                    /> */}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      class="form-control text-light customInput"
                      value={userData.phone}
                      onChange={handleChange}
                      id="phone"
                      placeholder="Mobile Number"
                      type="number"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                      style={{ background: "#333", color: "white" }}
                    />
                    {/* <TextField
                      sx={{ width: "100%" }}
                      value={userData.phone}
                      onChange={handleChange}
                      id="phone"
                      label="Mobile Number"
                      variant="outlined"
                      type="number"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                    /> */}
                    {/* <div > */}

                    {/* </div> */}
                  </div>
                  <div className="form-group mb-3">
                    <div class="input-group mb-3">
                      <input
                        type={showPassword ? "text" : "password"}
                        class="form-control customInput"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon2"
                        value={userData.password}
                        onChange={handleChange}
                        id="password"
                      />
                      <span
                        class="input-group-text"
                        id="basic-addon2"
                        onClick={handleClickShowPassword}
                        style={{ background: "#333", color: primaryColor }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </span>
                    </div>
                    {/* <FormControl
                      sx={{ width: "100%" }}
                      variant="outlined"
                     >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        label="Password"
                        sx={{ width: "100%" }}
                        value={userData.password}
                        onChange={handleChange}
                        id="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl> */}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      class="form-control text-light customInput"
                      onChange={handleChange}
                      value={userData.referral_code}
                      id="referral_code"
                      placeholder="Referral code (Optional)"
                      style={{ background: "#333", color: "white" }}
                    />
                    {/* <TextField
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      value={userData.referral_code}
                      id="referral_code"
                      label="Referral code (Optional)"
                      variant="outlined"
                    /> */}
                  </div>
                  <button
                    className="btn w-100"
                    style={{ background: primaryBoxBgColor }}
                    onClick={handleRegister}
                  >
                    Sign In
                  </button>
                  {/* <div className="form-group">
                    <LoadingButton
                      sx={{
                        padding: "8px",
                        width: "100%",
                      }}
                      size="large"
                      onClick={handleRegister}
                      endIcon={<SendIcon />}
                      loading={btnLoading}
                      loadingPosition="end"
                      variant="outlined"
                    >
                      <span>SIGN UP</span>
                    </LoadingButton>
                  </div> */}
                </form>
                <p className="text-center text-light mt-4">
                  Already have an account?{" "}
                  <Link style={{ color: primaryColor }} to="/signin">
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
};

export default SignUp;
