import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { primaryBoxBgColor } from "../../config";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const All = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid sx={{marginTop:"10px"}} container spacing={1}>
        <Grid item xs={4}>
          <Item
            sx={{ cursor: "pointer", background: primaryBoxBgColor }}
            onClick={() => navigate("/gift_section")}
          >
            <div className="gameCate4" >
              <img
                src={require("../../assets/img/game-category/gamecate9.jpg")}
                alt=""
              />
              <p>Win Go 1Min</p>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item
            sx={{ cursor: "pointer", background: primaryBoxBgColor }}
            onClick={() => navigate("/referral_details")}
          >
            <div className="gameCate4" >
              <img
                src={require("../../assets/img/game-category/gamecate9.jpg")}
                alt=""
              />
              <p>Win Go 3Min</p>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item
            sx={{ cursor: "pointer", background: primaryBoxBgColor }}
            onClick={() => navigate("/referral_details")}
          >
            <div className="gameCate4" >
              <img
                src={require("../../assets/img/game-category/gamecate9.jpg")}
                alt=""
              />
              <p>Win Go 5Min</p>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item
            sx={{ cursor: "pointer", background: primaryBoxBgColor }}
            onClick={() => navigate("/referral_details")}
          >
            <div className="gameCate4" >
              <img
                src={require("../../assets/img/game-category/gamecate9.jpg")}
                alt=""
              />
              <p>Win Go 10Min</p>
            </div>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default All;
