import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { displayWidth } from "../config";

const BackBtn = (props) => {
  const navigate = useNavigate();
  return (
    <nav className="navbar text-light bg-dark" style={{position:"fixed" , top:0, zIndex:"1030" , width:"500px"}}>
      <div className="container-fluid">
        <div
          className="navbar-brand" style={{cursor:"pointer"}}
          onClick={()=> navigate(-1)}
         >
          <BiArrowBack color="white"/>
        </div>
        <div>
         {props.title ? props.title : ""}
        </div>
        <div style={{cursor:"pointer"}} onClick={()=> navigate(props.rightNavigate)}>
        {props.rightText ? props.rightText : ""}
        </div>
      </div>
    </nav>
  );
};

export default BackBtn;
