import React from "react";
import BackBtn from "../components/BackBtn";
import { displayWidth } from "../config";

const Page_404 = () => {
  return (
    <div>
      <BackBtn title={"404"} />
      <div
        style={{
          width: displayWidth,
          height: "99vh",
          paddingTop: "60px!important",
          display:"flex",alignItems:"center"
        }}
      >
        <img
          src={require("../assets/img/404_page.jpg")}
          alt="404"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default Page_404;
