import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import Header from "../components/Header";
import loginBg from "../assets/img/illustration/1.png";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import BackBtn from "../components/BackBtn";
import signupLogo from "../assets/img/signupImg.png";
import { ToastContainer, toast } from "react-toastify";
import { api_url } from "../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { showUserDetail, updateUserProfile } from "../redux/stateSlice/authSlice";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  const [btnLoading, setBtnLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: user?.data?.name || "",
    phone: user?.data?.phone || "",
    email: user?.data?.email || "",
    own_ref_code: user?.data?.own_ref_code || "",
  });

  useEffect(() => {
    if (loading) {
      setBtnLoading(loading);
    } else {
      setBtnLoading(false);
    }

    if (user?.message === "User detail Updated") {
      notifySuccess(user?.message);
    }
  }, [user , loading]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
    };

    useEffect(() => {
      dispatch(showUserDetail())
     }, [])

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  return (
    <>
      {/* <Header /> */}
      <BackBtn />
      <ToastContainer />
      <div className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              <div className="w-100 p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">Edit Profile</h3>
                  </div>
                </div>
                <form action="#" className="signin-form">
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      id="name"
                      label="Name"
                      value={userData.name}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      id="bank_name"
                      value={userData.phone}
                      label="Phone"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      value={userData.email}
                      id="ifsc_code"
                      label="IFSC Code"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      value={userData.own_ref_code}
                      id="own_ref_code"
                      label="Your Referral Code"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className="form-group">
                    <LoadingButton
                      sx={{
                        marginTop: "10px",
                        padding: "8px",
                        width: "100%",
                      }}
                      size="large"
                      onClick={() => dispatch(updateUserProfile(userData))}
                      endIcon={<SendIcon />}
                      loading={btnLoading}
                      loadingPosition="end"
                      variant="outlined"
                    >
                      <span>SAVE</span>
                    </LoadingButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
};

export default EditProfile;
