import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { showUserDetail } from "../redux/stateSlice/authSlice";
import FooterTwo from "../components/FooterTwo";
import { api_url, primaryBoxBgColor, primaryColor } from "../config";
import axios from "axios";
import { Container } from "@mui/material";
// import balanceImg from "../assets/img/balance_bg.jpg";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import All from "../components/tab-content/All";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import OfferModal from "../components/modal/OfferModal";
import ComingSoon from "./ComingSoon";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loadingBar } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [getImages, setGetImages] = useState([""]);
  const [loginCheck, setLoginCheck] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the active index to move to the next slide
      setActiveIndex((prevIndex) => (prevIndex + 1) % getImages.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [activeIndex, getImages.length]);

  useEffect(() => {
    const checkTokenAndNavigate = async () => {
      try {
        const tokenGet = await localStorage.getItem("logintoken");
        console.log(tokenGet, "logingtok splash");
        // dispatch(showUserDetail(tokenGet));
        if (tokenGet != null) {
          setLoginCheck(false);
          // props.navigation.navigate('DrawerNavigation');
        } else {
          setLoginCheck(true);
          // props.navigation.navigate('Login');
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    };
    checkTokenAndNavigate();
  }, []);

  useEffect(() => {
    dispatch(showUserDetail());
  }, []);

  const fetchImages = async () => {
    axios
      .get(`${api_url}/banners`)
      .then((res) => {
        setLoading(true);
        console.log("res-banners", res.data);
        setGetImages(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("error-banners", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      {loadingBar ? <Spinner loading={loadingBar} /> : null}
      <Header />
      <div id="carouselExample" className="carousel slide" style={{marginTop:"107px"}}>
        <div className="carousel-inner">
          {getImages.map((item, index) => {
            return (
              <div
                key={item.id}
                className={`carousel-item ${
                  index === activeIndex ? "active" : ""
                }`}
              >
                <img
                  src={item.url}
                  className="d-block w-100 carouselImage"
                  alt={`image-${index + 1}`}
                  // style={{ height: "50vh" }}
                />
              </div>
            );
          })}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <OfferModal/>
      {/* {!loginCheck ? ( */}
        <div>
          <Container style={{ marginBottom: "10px", marginTop: "40px" }}>
            <h5 style={{ color: primaryColor, margin: "10px" }}>
              Lottery
            </h5>
            <hr color="white" />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Item
                    sx={{ cursor: "pointer", background: primaryBoxBgColor }}
                    onClick={() => navigate("/gift_section")}
                  >
                    <div className="gameCate">
                      <img
                        src={require("../assets/img/game-category/gamecate.png")}
                        alt=""
                      />
                      <p>Lottery</p>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item
                    sx={{ cursor: "pointer", background: primaryBoxBgColor }}
                    onClick={() => navigate("/coming_soon")}
                  >
                    <div className="gameCate">
                      <img
                        src={require("../assets/img/game-category/gamecate2.png")}
                        alt=""
                      />
                      <p>Original</p>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item
                    sx={{ cursor: "pointer", background: primaryBoxBgColor }}
                    onClick={() => navigate("/coming_soon")}
                  >
                    <div className="gameCate">
                      <img
                        src={require("../assets/img/game-category/gamecate3.png")}
                        alt=""
                      />
                      <p>Slot</p>
                    </div>
                  </Item>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sx={{}}>
                  <Item
                    sx={{
                      cursor: "pointer",
                      background: primaryBoxBgColor,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => navigate("/coming_soon")}
                  >
                    <div className="gameCate1">
                      <img
                        src={require("../assets/img/game-category/gamecate4.png")}
                        alt=""
                      />
                      <p>Sports</p>
                    </div>
                    <div className="gameCate1">
                      <img
                        src={require("../assets/img/game-category/gamecate5.png")}
                        alt=""
                      />
                      <p>Popular</p>
                    </div>
                    <div className="gameCate1">
                      <img
                        src={require("../assets/img/game-category/gamecate6.png")}
                        alt=""
                      />
                      <p>Casino</p>
                    </div>
                  </Item>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ marginTop: "1px" }}>
                <Grid item xs={6}>
                  <Item
                    sx={{ cursor: "pointer", background: primaryBoxBgColor }}
                    onClick={() => navigate("/gift_section")}
                  >
                    <div className="gameCate2">
                      <img
                        src={require("../assets/img/game-category/gamecate7.png")}
                        alt=""
                      />
                      <p>Lottery</p>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item
                    sx={{ cursor: "pointer", background: primaryBoxBgColor }}
                    onClick={() => navigate("/coming_soon")}
                  >
                    <div className="gameCate2">
                      <img
                        src={require("../assets/img/game-category/gamecate8.png")}
                        alt=""
                      />
                      <p>Slot</p>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Container sx={{ marginTop: "20px" }}>
            <Box
              sx={{
                width: "100%",
                overflowX: "auto!important",
                padding: "0px",
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  background: "#4e4e4e",
                  borderRadius: "10px",
                  overflowX: "auto!important",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    "& .MuiTabs-scroller": {
                      overflowX:"scroll!important",
                    },
                    "& .Mui-selected": {
                      color: "#333!important",
                      background: primaryBoxBgColor,
                      borderRadius: "10px",
                    },
                    "& .MuiTab-root": {
                      color: "gray",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <Tab label="All" {...a11yProps(0)} />
                  <Tab label="Win Go" {...a11yProps(1)} />
                  <Tab label="K3" {...a11yProps(2)} />
                  <Tab label="5D" {...a11yProps(3)} />
                  <Tab label="Trx Win" {...a11yProps(4)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <All />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <All />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
              <img src={require("../assets/img/coming_soon.jpg")} alt="" style={{width:"100%"}} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
              <img src={require("../assets/img/coming_soon.jpg")} alt="" style={{width:"100%"}} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
              <img src={require("../assets/img/coming_soon.jpg")} alt="" style={{width:"100%"}} />
              </CustomTabPanel>
            </Box>
          </Container>
          <Container>
            <h5 style={{ color: primaryColor, margin: "10px" }}>
              Winning Information
            </h5>
            <hr color="white" />
            <Carousel
              axis="vertical"
              autoPlay
              infiniteLoop
              showArrows={false}
              showIndicators={false}
              showThumbs={false}
            >
              <div>
                <div className="winInfoCon" style={{}}>
                  <div className="winInfoConSub1">
                    <img
                      style={{}}
                      src={require("../assets/img/logo/riv_logo.png")}
                    />
                    <p
                      style={{
                        color: "rgb(186 180 180)",
                        margin: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Lono****k
                    </p>
                  </div>
                  <div className="winInfoConSub1">
                    <img
                      style={{}}
                      src={require("../assets/img/game-category/gamecate3.png")}
                    />
                    <div>
                      <p
                        style={{
                          color: "#f6c768",
                          margin: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Receive ₹1398934
                      </p>
                      <p
                        style={{
                          color: "rgb(139, 139, 139)",
                          margin: "0px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Winning amount
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="winInfoCon" style={{}}>
                  <div className="winInfoConSub1">
                    <img
                      style={{}}
                      src={require("../assets/img/logo/riv_logo.png")}
                    />
                    <p
                      style={{
                        color: "rgb(186 180 180)",
                        margin: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Lono****k
                    </p>
                  </div>
                  <div className="winInfoConSub1">
                    <img
                      style={{}}
                      src={require("../assets/img/game-category/gamecate3.png")}
                    />
                    <div>
                      <p
                        style={{
                          color: "#f6c768",
                          margin: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Receive ₹1398934
                      </p>
                      <p
                        style={{
                          color: "rgb(139, 139, 139)",
                          margin: "0px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Winning amount
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="winInfoCon" style={{}}>
                  <div className="winInfoConSub1">
                    <img
                      style={{}}
                      src={require("../assets/img/logo/riv_logo.png")}
                    />
                    <p
                      style={{
                        color: "rgb(186 180 180)",
                        margin: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      Lono****k
                    </p>
                  </div>
                  <div className="winInfoConSub1">
                    <img
                      style={{}}
                      src={require("../assets/img/game-category/gamecate3.png")}
                    />
                    <div>
                      <p
                        style={{
                          color: "#f6c768",
                          margin: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        Receive ₹1398934
                      </p>
                      <p
                        style={{
                          color: "rgb(139, 139, 139)",
                          margin: "0px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Winning amount
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </Container>
          <Container style={{ marginBottom: "100px" }}>
            <h5 style={{ color: primaryColor, margin: "10px" }}>
              Today's earning chart
            </h5>
            <hr color="white" />
            <div>
              <div className="stageCon">
                <div className="stageConSub">
                  <img
                    className="stageCrownImg"
                    src={require("../assets/img/crown2.png")}
                    alt=""
                  />
                  <img
                    className="stageFaceImg"
                    src={require("../assets/img/face.png")}
                    alt=""
                  />
                  <img
                    className="stageRankImg"
                    src={require("../assets/img/place2.png")}
                    alt=""
                  />
                  <p className="stageConSubText"> Mem***h</p>
                  <p className="stageConSubText">₹1398934</p>
                </div>
                <div className="stageConSub">
                  <div className="stageConSub">
                    <img
                      className="stageCrownImg"
                      src={require("../assets/img/crown2.png")}
                      alt=""
                    />
                    <img
                      className="stageFaceImg"
                      src={require("../assets/img/face.png")}
                      alt=""
                    />
                    <img
                      className="stageRankImg"
                      src={require("../assets/img/place2.png")}
                      alt=""
                    />
                    <p className="stageConSubText"> Mem***h</p>
                    <p className="stageConSubText">₹1398934</p>
                  </div>
                </div>
                <div className="stageConSub">
                  <div className="stageConSub">
                    <img
                      className="stageCrownImg"
                      src={require("../assets/img/crown2.png")}
                      alt=""
                    />
                    <img
                      className="stageFaceImg"
                      src={require("../assets/img/face.png")}
                      alt=""
                    />
                    <img
                      className="stageRankImg"
                      src={require("../assets/img/place2.png")}
                      alt=""
                    />
                    <p className="stageConSubText"> Mem***h</p>
                    <p className="stageConSubText">₹1398934</p>
                  </div>
                </div>
              </div>
              <div className="winInfoCon" style={{ marginTop: "10px" }}>
                <div className="winInfoConSub1">
                  <p
                    style={{
                      color: "#f6c768",
                      marginLeft: "10px",
                      marginRight: "20px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    4
                  </p>
                  <img
                    style={{}}
                    src={require("../assets/img/logo/riv_logo.png")}
                  />
                  <p
                    style={{
                      color: "rgb(186 180 180)",
                      margin: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    Lono****k
                  </p>
                </div>
                <div className="winInfoConSub1">
                  <div>
                    <p
                      style={{
                        color: "#f6c768",
                        margin: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      ₹1398934
                    </p>
                  </div>
                </div>
              </div>
              <div className="winInfoCon" style={{ marginTop: "10px" }}>
                <div className="winInfoConSub1">
                  <p
                    style={{
                      color: "#f6c768",
                      marginLeft: "10px",
                      marginRight: "20px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    5
                  </p>
                  <img
                    style={{}}
                    src={require("../assets/img/logo/riv_logo.png")}
                  />
                  <p
                    style={{
                      color: "rgb(186 180 180)",
                      margin: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    Lono****k
                  </p>
                </div>
                <div className="winInfoConSub1">
                  <div>
                    <p
                      style={{
                        color: "#f6c768",
                        margin: "0px",
                        fontWeight: "bold",
                      }}
                    >
                      ₹1398934
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      {/*  ) : (
           <div style={{ display: "flex", justifyContent: "center" }}>
             <div
               onClick={() => navigate("/signin")}
               className="btn btn-primary"
               style={{
                 margin: "130px 10px",
                 fontWeight: "bold",
                 fontSize: "20px",
               }}
             >
               Please Login
             </div>
           </div>
         )} */}
      <FooterTwo value={0} />
    </>
  );
};

export default Home;
