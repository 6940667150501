import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useNavigate, useNavigation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { AiOutlineHome } from "react-icons/ai";
import { SlDiamond } from "react-icons/sl";
import { GoTrophy } from "react-icons/go";
import { IoWalletOutline } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import { displayWidth, primaryColor } from "../config";



const FooterTwo = ({ value }) => {
  const navigation = useNavigate();

  //   const [value, setValue] = React.useState(0);


  const activeTabColor = `${primaryColor}!important`;
  const tabColor = "#969799";

  return (
    <Box sx={{ marginTop: "90px" , display:"flex" , justifyContent:"center"  }}>
      <Paper
        sx={{ position: "fixed", bottom: 0 , width: displayWidth,}}
        elevation={5}
      >
        <BottomNavigation
          sx={{ height:"75px",
            backgroundColor: "#242424",
            "& .Mui-selected": {
              color: activeTabColor,
            },
            "& .Mui-selected .MuiSvgIcon-root": {
              color: {activeTabColor},
            },
            "& .MuiBottomNavigationAction-root": {
              color: tabColor,
            },
            "& .MuiBottomNavigationAction-root .MuiSvgIcon-root": {
              color: tabColor, 
            },
          }}
          showLabels
          value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
        >
          <BottomNavigationAction
            onClick={() => navigation("/")}
            label="Home"
            icon={<AiOutlineHome style={{fontSize:"30px"}} />}
          />
          <BottomNavigationAction
            onClick={() => navigation("/activity")}
            label="Activity"
            icon={<GoTrophy style={{fontSize:"30px"}} />}
          />
          <BottomNavigationAction
            onClick={() => navigation("/promotion")}
            label="Promotion"
            icon={<SlDiamond style={{fontSize:"30px"}} />}
          />
          <BottomNavigationAction
            onClick={() => navigation("/wallet")}
            label="Wallet"
            icon={<IoWalletOutline style={{fontSize:"30px"}} />}
          />
          <BottomNavigationAction
            onClick={() => navigation("/profile_section")}
            label="Profile"
            icon={<FaRegUserCircle  style={{fontSize:"30px"}}/>}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default FooterTwo;
