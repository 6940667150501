import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { FaRegBell, FaBell, FaRupeeSign } from "react-icons/fa";
import Badge from "@mui/material/Badge";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../components/FooterTwo";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { showUserDetail } from "../redux/stateSlice/authSlice";
import axios from "axios";
import { api_url, primaryBoxBgColor, primaryColor } from "../config";
import Spinner from "../components/Spinner";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Grid from "@mui/material/Grid";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loadingBar } = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState([""]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(showUserDetail());
  }, []);

  useEffect(() => {
    if (user) {
      // console.log("user", user);
      setUserDetails(user?.data);
    }
  }, [user]);

  const handleLogout = async () => {
    const userToken = localStorage.getItem("logintoken");
    try {
      // setLoading(true);
      const response = await axios.get(`${api_url}/auth/logout`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response) {
        // setLoading(false);
        // console.log("res", response);
        // console.log("res", response.data);
        if (response.data.message === "Logout successfully") {
          // Toast.show(response.data.message);
          // props.navigation.navigate('Login');
          localStorage.setItem("logintoken", "");
          localStorage.removeItem("logintoken");
          navigate("/signin");
        }
      }
    } catch (error) {
      // setLoading(false);
      console.log("redux-error-logout", error);
    }
  };

  const handleShareClick = () => {
    const referralCode = userDetails?.own_ref_code;
    const websiteLink = `https://theriv.buzz/signup?ref_code=${referralCode}`;
    // const websiteLink = `http://localhost:3000/signup?ref_code=${referralCode}`;
    const imageUrl = "https://theriv.buzz/public/sharingImage/rivImage.png";
    const shareMessage = `Hey 👋,
    \nThanks for referring your friends to join RIV. You will receive 10% bonus amount of their first deposite who use your refferral code.
    \nUsing this referral code ${referralCode} and get reward and earn more money.
    \nJoin us and earn more \n`;
    // Use the Web Share API if available

    const shareData = {
      title: "RIV",
      text: shareMessage,
      url: websiteLink,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      // console.log('Web Share API not supported. Implement your own share logic.');
    }
  };

  return (
    <>
      {loadingBar ? <Spinner loading={loadingBar} /> : null}
      <Header />
      <div
        style={{
          background: primaryBoxBgColor,
          borderRadius: "20px",
          marginTop: "100px",
          marginBottom: "50px",
        }}
        className="py-3"
      >
        <div className="container">
          <header style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <div className="top-container">
                <Avatar alt="name" src={require("../assets/img/face.png")} />
                <div className="ml-3">
                  <div className="name bold" style={{ color: "#624100" }}>
                    MEMBER{userDetails.phone} ({userDetails.name})
                  </div>
                  <div
                    style={{
                      background: "#624100",
                      padding: "5px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      color: primaryColor,
                      fontSize: "13px",
                    }}
                  >
                    UID | {userDetails.phone}{" "}
                    <ContentCopyIcon fontSize="17px" />
                  </div>
                  <div className="bold" style={{ color: "#624100" }}>
                    {new Date(userDetails.created_at).toDateString()}
                    {" ,"}
                    {new Date(userDetails.created_at).toLocaleTimeString()}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
            <div
              style={{ cursor: "pointer" }}
              className=""
              onClick={() => navigate("/notification")}
            >
              <Badge badgeContent={"●"} sx={{ color: "red" }}>
                <FaRegBell color="white" size={25} />
              </Badge>
            </div>
          </header>
        </div>
      </div>
      <div
        className="d-flex p-2 m-2 "
        style={{
          justifyContent: "center",
          flexDirection: "column",
          background: "#3f3f3f",
          borderRadius: "10px",
          color: primaryColor,
        }}
      >
        <div>
          <p className="bold m-0">Total Balance</p>
          <p className="" style={{ fontSize: "23px", fontWeight: "bold" }}>
            ₹{userDetails.wallet ? userDetails.wallet : 0}.00
          </p>
        </div>
        <hr color={primaryColor} />
        <div className="d-flex" style={{ justifyContent: "space-around" }}>
          <div
            className="d-flex"
            style={{ flexDirection: "column" }}
            onClick={() => navigate("/wallet")}
          >
            <img
              src={require("../assets/img/icons/wallet.png")}
              alt=""
              style={{ width: "40px" , cursor:"pointer"}}
            />{" "}
            Wallet
          </div>
          <div
            className="d-flex"
            style={{ flexDirection: "column" }}
            onClick={() => navigate("/deposit")}
          >
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" , cursor:"pointer"}}
            />{" "}
            Deposit
          </div>
          <div
            className="d-flex"
            style={{ flexDirection: "column" }}
            onClick={() => navigate("/withdrawal")}
          >
            <img
              src={require("../assets/img/icons/withdraw.png")}
              alt=""
              style={{ width: "40px" , cursor:"pointer"}}
            />{" "}
            Withdraw
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/vip.png")}
              alt=""
              style={{ width: "40px", cursor:"pointer" }}
            />{" "}
            VIP
          </div>
        </div>
      </div>
      <Grid container spacing={3} sx={{ marginTop: "1px", padding: "10px" }}>
        <Grid item xs={6}>
        <div className="transCon" style={{ background: "#3F3F3F", cursor:"pointer" }} onClick={()=>navigate("/withdrawal_record")}>
            <img
              style={{}}
              src={require("../assets/img/icons/transaction-1.png")}
            />
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                Bet
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                My betting history
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
        <div className="transCon" style={{ background: "#3F3F3F", cursor:"pointer" }} onClick={()=>navigate("/withdrawal_record")}>
            <img
              style={{}}
              src={require("../assets/img/icons/transaction-3.png")}
            />
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                Transaction
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                My transaction history
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="transCon" style={{ background: "#3F3F3F" , cursor:"pointer"}} onClick={()=>navigate("/recharge_record")}>
            <img
              style={{}}
              src={require("../assets/img/icons/transaction-2.png")}
            />
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                Deposite
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                My deposit history
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
        <div className="transCon" style={{ background: "#3F3F3F", cursor:"pointer" }} onClick={()=>navigate("/withdrawal_record")}>
            <img
              style={{}}
              src={require("../assets/img/icons/transaction-4.png")}
            />
            <div>
              <p
                style={{
                  color: "#f6c768",
                  margin: "0px",
                  fontWeight: "bold",
                }}
              >
                Withdraw
              </p>
              <p
                style={{
                  color: "rgb(139, 139, 139)",
                  margin: "0px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                My withdraw history
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <div>
        <div className="promoTab" onClick={()=>navigate("/notification")}>
          <div className="promoTabSub">
            <FaRegBell style={{ color: primaryColor }} /> Notification
          </div>
          <div>
            <span style={{ color: primaryColor }}>4</span>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div className="promoTab" onClick={()=>navigate("/gift_voucher")}>
          <div className="promoTabSub">
            <LocalAtmOutlinedIcon style={{ color: primaryColor }} /> Gift
          </div>
          <div>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div className="promoTab">
          <div className="promoTabSub">
            <SignalCellularAltOutlinedIcon style={{ color: primaryColor }} />{" "}
            Game statistics
          </div>
          <div>
            <ArrowForwardIosIcon />
          </div>
        </div>
        <div className="promoTab">
          <div className="promoTabSub">
            <LanguageOutlinedIcon style={{ color: primaryColor }} /> Language
          </div>
          <div>
            <span style={{ color: primaryColor }}>English</span>
            <ArrowForwardIosIcon />
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          background: "#3F3F3F",
          padding: "10px",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        <h5 className="text-light mt-2">Service center</h5>
        <hr className="text-light" />
        <Grid container spacing={1} sx={{}}>
          <Grid item xs={4}>
            <div
              className="d-flex text-light"
              style={{ flexDirection: "column" }}
            >
              <SettingsOutlinedIcon style={{ color: "rgb(217, 172, 79)" }} />{" "}
              Setting
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="d-flex text-light"
              style={{ flexDirection: "column" }}
            >
              <FeedbackOutlinedIcon style={{ color: "rgb(217, 172, 79)" }} />{" "}
              Feedback
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="d-flex text-light"
              style={{ flexDirection: "column" }}
            >
              <NotificationsActiveOutlinedIcon
                style={{ color: "rgb(217, 172, 79)" }}
              />{" "}
              Notification
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="d-flex text-light"
              style={{ flexDirection: "column" }}
            >
              <SupportAgentOutlinedIcon
                style={{ color: "rgb(217, 172, 79)" }}
              />{" "}
              24/7 Customer's service
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="d-flex text-light"
              style={{ flexDirection: "column" }}
            >
              <MenuBookOutlinedIcon style={{ color: "rgb(217, 172, 79)" }} />{" "}
              Beginner's Guide
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className="d-flex text-light"
              style={{ flexDirection: "column" }}
            >
              <InfoOutlinedIcon style={{ color: "rgb(217, 172, 79)" }} /> About
              Us
            </div>
          </Grid>
        </Grid>
        {/* <div className="d-flex" style={{ justifyContent: "space-around" }}>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            Setting
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            Feedback
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            Notification
          </div>
        </div>
        <div className="d-flex" style={{ justifyContent: "space-around" }}>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            24/7 Customer s\service
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            Beginner's Guide
          </div>
          <div className="d-flex" style={{ flexDirection: "column" }}>
            <img
              src={require("../assets/img/icons/deposit.png")}
              alt=""
              style={{ width: "40px" }}
            />{" "}
            About Us
          </div>
        </div> */}
      </div>

      <button
        className="btn"
        onClick={handleLogout}
        style={{
          background: primaryBoxBgColor,
          padding: "10px",
          textAlign: "center",
          margin: "10px",
          borderRadius: "10px",
          width: "90%",
          marginBottom: "0px",
        }}
      >
        <ExitToAppOutlinedIcon style={{ color: "" }} /> Logout
      </button>

      <FooterTwo value={4} />
    </>
  );
};

export default Profile;
