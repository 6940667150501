import React, { useState, useEffect } from "react";
import BackBtn from "../components/BackBtn";
import giftV from "../assets/img/gift_voucher.png";
import { Container, Grid } from "@mui/material";
import { api_url, displayWidth } from "../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../components/Spinner";

const GiftVoucher = () => {
  const [loading, setLoading] = useState(false);

  const handleRedeem = async () => {
    const loginToken = localStorage.getItem("logintoken");
    try {
      setLoading(true);
      const response = await axios.get(`${api_url}/bids/redeem`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      });
      console.log("his-data", response.data);
      // setRows(response?.data?.data);
      setLoading(false);
      notifySuccess(response.data.message)
    } catch (error) {
      setLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        notifyErr(error.response.data.message)
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };
  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  return (
    <>
      {loading ? <Spinner loading={loading} /> : null}
      <BackBtn title={"Gift"} />
      <ToastContainer />
      <Container sx={{ width: displayWidth, height: "99vh", paddingTop: "60px!important" }}>
        <div className="d-flex" style={{ justifyContent: "center" }}>
          <img
            src={giftV}
            className="d-block"
            style={{ width: "70%", borderRadius: "10px" }}
            alt="image"
          />
          <button
            onClick={handleRedeem}
            className="btn btn-dark"
            style={{
              backgroundColor: "#292929",
              color: "#f5dca6",
              display: "flex",
              alignItems: "center",
              borderStyle: "dashed",
              borderColor: "white",
            }}
          >
            Redeem
          </button>
        </div>
      </Container>
    </>
  );
};

export default GiftVoucher;
