import React from 'react'
import BackBtn from '../components/BackBtn'

const ComingSoon = () => {
  return (
    <div>
        <BackBtn title={"Coming Soon"}/>
        <img src={require("../assets/img/coming_soon.jpg")} alt="coming soon" style={{width:"100%" , marginTop:"68px"}} />
    </div>
  )
}

export default ComingSoon