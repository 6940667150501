import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const PriceModal = ({
  open,
  handleClose,
  initialQuantity,
  value,
  handleBidBtn,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const [selectedValue, setSelectedValue] = useState(10);

  // const quantity = initialQuantity ;
  // console.log("initialQuantity" , initialQuantity)
  // useEffect(() => {
  //   setQuantity(initialQuantity)
  // }, [initialQuantity])

  const handleSelection = (value) => {
    setSelectedValue(value);
    setQuantity(1);
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    if (selectedValue === 10 * quantity) {
      setSelectedValue(selectedValue + 10);
    } else if (selectedValue === 100 * quantity) {
      setSelectedValue(selectedValue + 100);
    } else if (selectedValue === 1000 * quantity) {
      setSelectedValue(selectedValue + 1000);
    } else if (selectedValue === 10000 * quantity) {
      setSelectedValue(selectedValue + 10000);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      if (selectedValue === 10 * quantity) {
        setSelectedValue(selectedValue - 10);
      } else if (selectedValue === 100 * quantity) {
        setSelectedValue(selectedValue - 100);
      } else if (selectedValue === 1000 * quantity) {
        setSelectedValue(selectedValue - 1000);
      } else if (selectedValue === 10000 * quantity) {
        setSelectedValue(selectedValue - 10000);
      }
    }
  };

  const handleCloseSecond = () => {
    setQuantity(1);
    handleClose();
    setSelectedValue(10);
  };

  const handleSubmit = () => {
    setQuantity(1);
    handleBidBtn(selectedValue);
    setSelectedValue(10);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          padding: "20px",
          textAlign: "center",
          color: "white",
          backgroundColor:
            value === "joingreen"
              ? "green"
              : value === "joinred"
              ? "red"
              : value === "joinviolet"
              ? "violet"
              : "blue",
        }}
      >
        {value === "joingreen"
          ? "Join Green"
          : value === "joinred"
          ? "Join Red"
          : value === "joinviolet"
          ? "Join Violet"
          : value}
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          sx={{ padding: "20px" }}
        >
          <FormControlLabel
            onClick={() => handleSelection(10)}
            value="10"
            control={<Radio />}
            label="₹10"
            checked={selectedValue === 10 * quantity ? true : false}
          />
          <FormControlLabel
            onClick={() => handleSelection(100)}
            value="100"
            control={<Radio />}
            label="₹100"
            checked={selectedValue === 100 * quantity ? true : false}
          />
          <FormControlLabel
            onClick={() => handleSelection(1000)}
            value="1000"
            control={<Radio />}
            label="₹1000"
            checked={selectedValue === 1000 * quantity ? true : false}
          />
          <FormControlLabel
            onClick={() => handleSelection(10000)}
            value="10000"
            control={<Radio />}
            label="₹10000"
            checked={selectedValue === 10000 * quantity ? true : false}
          />
        </RadioGroup>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <IconButton onClick={handleDecrement}>
            <RemoveCircleIcon />
          </IconButton>
          <Typography variant="h6">{quantity}</Typography>
          <IconButton onClick={handleIncrement}>
            <AddCircleIcon />
          </IconButton>
        </div>
        <DialogContentText sx={{ paddingTop: "20px" }}>
          Total Value : <strong>{`₹${selectedValue}`}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="primary">
          Cancel
        </Button> */}
        <Button onClick={handleCloseSecond} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
        {/* <Button onClick={() => handleBidBtn(selectedValue)} color="primary">
          Submit
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default PriceModal;
