import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { api_url, displayWidth } from "../../config";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import BackBtn from "../BackBtn";
import { Container, Divider, TableHead } from "@mui/material";
import Spinner from "../Spinner";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Modal from "@mui/material/Modal";
import { showUserDetail } from "../../redux/stateSlice/authSlice";
import { useDispatch } from "react-redux";
import noDataImg2 from "../../assets/img/no_data3.jpg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

// const rows = [
//   createData('Cupcake', 305, 3.7),
//   createData('Donut', 452, 25.0),
//   createData('Eclair', 262, 16.0),
//   createData('Frozen yoghurt', 159, 6.0),
//   createData('Gingerbread', 356, 16.0),
//   createData('Honeycomb', 408, 3.2),
//   createData('Ice cream sandwich', 237, 9.0),
//   createData('Jelly Bean', 375, 0.0),
//   createData('KitKat', 518, 26.0),
//   createData('Lollipop', 392, 0.2),
//   createData('Marshmallow', 318, 0),
//   createData('Nougat', 360, 19.0),
//   createData('Oreo', 437, 18.0),
// ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const RechargeRecord = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [reasonGet, setReasonGet] = useState("");

  const handleClose = () => setOpen(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    const loginToken = localStorage.getItem("logintoken");
    try {
      setLoading(true);
      const response = await axios.get(`${api_url}/request/recharge`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      });
      console.log("rcg-data", response.data);
      setRows(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(showUserDetail());
  }, []);

  const handleOpenModal = (value) => {
    setOpen(true);
    setReasonGet(value);
  };

  return (
    <>
      {loading ? <Spinner loading={loading} /> : null}
      <BackBtn title={"Deposit records"} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold" }}
          >
            Reason
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {reasonGet}
          </Typography>
        </Box>
      </Modal>
      <Box
        sx={{
          width: displayWidth,
          height: "99vh",
          paddingTop: "60px!important",
        }}
      >
        {rows?.length === 0 ? (
          <div>
            <img
              style={{ width: "100%" }}
              src={require("../../assets/img/nodata.png")}
              alt=""
            />
          </div>
        ) : (
          <TableContainer component={Paper} sx={{ padding: "0px 10px" }}>
            <Table sx={{ width: "100%" }} aria-label="custom pagination table">
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row) => {
                  const inputDate = row.created_at;
                  // console.log('inputDate' , inputDate)
                  const date = new Date(inputDate);
                  const day = date.getDate();
                  const month = date.getMonth() + 1; // Months are zero-indexed
                  const year = date.getFullYear();
                  const options = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  };

                  const formattedTime = date.toLocaleTimeString(
                    "en-US",
                    options
                  );

                  const formattedDate = date.toLocaleString("en-US");
                  // const formattedDate = `${day}-${month}-${year} , ${formattedTime} `;

                  return (
                    <TableRow key={row.id}>
                      <Container fixed sx={{ paddingBottom: "15px" }}>
                        <Item>
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: "space-between",
                              padding: "10px",
                              background: "rgb(246 246 246)",
                            }}
                          >
                            <div
                              className="btn text-white"
                              style={{ background: "rgb(126 137 255)" }}
                            >
                              Add Money
                            </div>
                            <div
                              className="btn"
                              style={{
                                color: "white",
                                background:
                                  row.status === 0
                                    ? "blue"
                                    : row.status === 1
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {" "}
                              {row.status === 0
                                ? "Pending"
                                : row.status === 1
                                ? "Success"
                                : "Reject"}
                            </div>
                          </div>
                          <Divider />
                          <div
                            className="d-flex m-3"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>Amount</div>
                            <div
                              style={{ color: "#ea9500", fontWeight: "bold" }}
                            >
                              {row.status === 1
                                ? `+₹${row.amount}`
                                : `-₹${row.amount}`}
                            </div>
                          </div>
                          <div
                            className="d-flex m-3"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>Date</div>
                            <div style={{}}>{row.created_at}</div>
                          </div>
                          <div
                            className="d-flex mx-3 py-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>Reason(if rejected)</div>
                            <div style={{ color: "green" }}>
                              {row.status === 2 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleOpenModal(row.reason)}
                                >
                                  <VisibilityOutlinedIcon
                                    sx={{ fontSize: "20px", color: "#480096" }}
                                  />
                                </div>
                              ) : (
                                <div style={{}}>
                                  <VisibilityOffOutlinedIcon
                                    sx={{ fontSize: "20px", color: "#480096" }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Item>
                      </Container>
                      {/* <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: "0px" }}
                     >
                      {row.created_at}
                    </TableCell>
                    <TableCell style={{}} align="right">
                      {`₹${row.amount}`}
                    </TableCell>
                    <TableCell style={{}} align="right">
                      {row.status === 2 ? (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenModal(row.reason)}
                        >
                          <VisibilityOutlinedIcon
                            sx={{ fontSize: "20px", color: "#480096" }}
                          />
                        </div>
                      ) : (
                        <div style={{}}>
                          <VisibilityOffOutlinedIcon
                            sx={{ fontSize: "20px", color: "#480096" }}
                          />
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        color:
                          row.status === 0
                            ? "blue"
                            : row.status === 1
                            ? "green"
                            : "red",
                      }}
                      align="right"
                     >
                      {row.status === 0
                        ? "Pending"
                        : row.status === 1
                        ? "Success"
                        : "Reject"}
                    </TableCell> */}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default RechargeRecord;
