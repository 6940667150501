import React from "react";
import BackBtn from "../components/BackBtn";
import FirstDepositContent from "../components/modal/FirstDepositContent";

const FirstDeposit = () => {
  return (
    <div>
      <BackBtn title={"First Deposit"} />
      <div
            class="card"
            style={{
              background: "#333",
              color: "white",
              padding: "13px",
            }}
          >
            <FirstDepositContent />
          </div>
    </div>
  );
};

export default FirstDeposit;
