import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { Link, useNavigate } from "react-router-dom";
import BackBtn from "../components/BackBtn";
import { ToastContainer, toast } from "react-toastify";
import { api_url } from "../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import UpdateIcon from "@mui/icons-material/Update";
import { showUserDetail } from "../redux/stateSlice/authSlice";
import Spinner from "../components/Spinner";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  paddingBottom: "20px",
}));



const AddMoney = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [btnLoading, setBtnLoading] = useState(false);
  const [acc_id, setAcc_id] = useState("");
  const [getBankDetail, setGetBankDetail] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    amount: "",
    reference_no: "",
    pay_through: "",
    account_id: acc_id,
  });

  const fetchBankDetail = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${api_url}/account_info`);
      console.log(response.data, "res-ac-details");
      if (response.data.success === true) {
        setGetBankDetail(response?.data?.data);
        console.log("response?.data?.data?.id", response?.data?.data?.id);
        setAcc_id(response?.data?.data?.id);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };
  useEffect(() => {
    fetchBankDetail();
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      account_id: acc_id,
    }));
  }, [acc_id]);

  const handleRechargeRequest = async () => {
    if (
      userData.amount === "" ||
      userData.pay_through === "" ||
      userData.reference_no === ""
    ) {
      notifyErr("Fill in all the fields!");
      return false;
    }
    try {
      setBtnLoading(true);
      console.log("userdata", userData);
      const loginToken = localStorage.getItem("logintoken");
      const response = await axios.post(
        `${api_url}/request/recharge`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );
      console.log(response.data, "res");
      setBtnLoading(false);
      // setUserDetails(response.data);
      if (response.data.success === true) {
        notifySuccess(response.data.message);
        setUserData({
          amount: "",
          pay_through: "",
          reference_no: "",
        });
      }
    } catch (error) {
      notifyErr(error.response.data.message);
      setBtnLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  useEffect(() => {
    dispatch(showUserDetail());
  }, []);

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  return (
    <>
      {/* <Header /> */}
      <BackBtn />
      {loading ? <Spinner loading={loading} /> : null}
      <ToastContainer />
      <div className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              <div className="w-100 p-4 p-md-5">
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <div className="w-100 text-center">
                    <p className="bold m-0">Total Balance</p>
                    <p className="bold my-2" style={{ fontSize: "27px" }}>
                      ₹{user?.data?.wallet}
                    </p>
                  </div>
                  <Box
                    sx={{
                      flexGrow: 1,
                    //   border: "1px solid black",
                    //   padding: "20px",
                    //   borderRadius: "10px",
                    marginBottom: "20px",
                    
                     }}
                  >
                    <Item>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                            UPI
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ fontSize: "18px", fontWeight: "bold" ,color:"blue"}}>
                            {getBankDetail?.upi}
                          </div>
                        </Grid>
                      </Grid>
                    </Item>
                  </Box>
                </div>
                <form className="signin-form">
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      id="amount"
                      name="amount"
                      label="Amount"
                      value={userData.amount}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      id="reference_no"
                      name="reference_no"
                      label="Reference No./UTR No."
                      value={userData.reference_no}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Pay By
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={userData.pay_through}
                          name="pay_through"
                          label="Age"
                          onChange={handleChange}
                        >
                          <MenuItem value={"Paytm"}>Paytm</MenuItem>
                          <MenuItem value={"Google Pay"}>Google Pay</MenuItem>
                          <MenuItem value={"PhonePe"}>PhonePe</MenuItem>
                          <MenuItem value={"Amazon pay"}>Amazon pay</MenuItem>
                          <MenuItem value={"other"}>Other UPI Methode</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <LoadingButton
                    sx={{
                      marginTop: "10px",
                      padding: "8px",
                      width: "100%",
                    }}
                    size="large"
                    onClick={handleRechargeRequest}
                    endIcon={<SendIcon />}
                    loading={btnLoading}
                    loadingPosition="end"
                    variant="outlined"
                  >
                    <span>add money</span>
                  </LoadingButton>
                </form>
                <div style={{ marginTop: "30px", textAlign: "center" }}>
                  <Link to={"/recharge_record"}>
                    <UpdateIcon /> View History
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
};

export default AddMoney;
