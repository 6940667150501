import React from "react";
import { primaryBoxBgColor, primaryColor } from "../../config";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Box } from "@mui/material";

const FirstDepositContent = () => {
  const data = [
    {
      id: 1,
      amount: 200000,
      discount: 10000,
    },
    {
      id: 2,
      amount: 100000,
      discount: 5000,
    },
    {
      id: 3,
      amount: 30000,
      discount: 2000,
    },
    {
      id: 4,
      amount: 10000,
      discount: 600,
    },
    {
      id: 5,
      amount: 3000,
      discount: 300,
    },
    {
      id: 6,
      amount: 1000,
      discount: 150,
    },
    {
      id: 7,
      amount: 300,
      discount: 60,
    },
    {
      id: 8,
      amount: 100,
      discount: 20,
    },
  ];

  return (
    <div>
      <div className="cart-header p-2">
        <h4 className="text-center">Extra first deposit bonus</h4>
      </div>
      <hr className="text-light" />
      {data.map((data) => (
        <div key={data.id}>
          <div className="d-flex justify-content-between">
            <h5 class="card-title">
              First deposit{" "}
              <span style={{ color: primaryColor }}>{data.amount}</span>{" "}
            </h5>
            <h5 class="card-title" style={{ color: primaryColor }}>
              +{data.discount}.00
            </h5>
          </div>
          <div class="card-body">
            <p class="card-text">
              Deposit {data.amount} for the first time in your account and you
              can receive 10000
            </p>
          </div>
          <div className="d-flex justify-content-around">
            <button className="btn btn-dark">0/{data.discount}</button>
            <a
              href="/deposit"
              class="btn"
              style={{ background: primaryBoxBgColor }}
            >
              Deposit
            </a>
          </div>
          <hr className="text-light" />
        </div>
      ))}
    </div>
  );
};

export default FirstDepositContent;
