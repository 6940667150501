import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import Header from "../components/Header";
import loginBg from "../assets/img/illustration/1.png";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import BackBtn from "../components/BackBtn";
import signupLogo from "../assets/img/signupImg.png";
import { ToastContainer, toast } from "react-toastify";
import { api_url, primaryBoxBgColor, primaryColor } from "../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  showUserDetail,
  updateUserProfile,
} from "../redux/stateSlice/authSlice";

const EditBankDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  const [btnLoading, setBtnLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: user?.data?.name,
    acc_no: user?.data?.acc_no || "",
    bank_name: user?.data?.bank_name || "",
    ifsc_code: user?.data?.ifsc_code || "",
    upi_id: user?.data?.upi_id || "",
  });

  useEffect(() => {
    if (loading) {
      setBtnLoading(loading);
    } else {
      setBtnLoading(false);
    }

    if (user?.message === "User detail Updated") {
      notifySuccess("Bank Details Updated");
    }
  }, [user, loading]);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    dispatch(showUserDetail());
  }, []);

  const handleBankDetail = async () => {
    if (
      userData.acc_no === "" ||
      userData.bank_name === "" ||
      userData.ifsc_code === ""
    ) {
      // console.log("first");
      setBtnLoading(false);
      notifyErr("Please fill all required fields!");
      return false;
    }
    dispatch(updateUserProfile(userData));
  };

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  return (
    <>
      {/* <Header /> */}
      <BackBtn />
      <ToastContainer />
      <div className="container text-light mt-3">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              <div className="w-100 p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">Bank Details</h3>
                  </div>
                </div>
                <form action="#" className="signin-form">
                  <div className="form-group mb-3">
                    <div class="mb-3">
                      <label for="acc_no" class="form-label" style={{color:primaryColor}}>
                        Account Number
                      </label>
                      <input
                        class="form-control customInput"
                        id="acc_no"
                        value={userData.acc_no}
                        onChange={handleChange}
                        type="number"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="bank_name" class="form-label" style={{color:primaryColor}}>
                        Bank Name
                      </label>
                      <input
                        type="text"
                        class="form-control customInput"
                        id="bank_name"
                        value={userData.bank_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="mb-3">
                      <label for="ifsc_code" class="form-label" style={{color:primaryColor}}>
                        IFSC Code
                      </label>
                      <input
                        type="text"
                        class="form-control customInput"
                        onChange={handleChange}
                        value={userData.ifsc_code}
                        id="ifsc_code"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="upi_id" class="form-label" style={{color:primaryColor}}>
                        UPI Id
                      </label>
                      <input
                        type="text"
                        class="form-control customInput"
                        onChange={handleChange}
                        value={userData.upi_id}
                        id="upi_id"
                      />
                    </div>
                    {/* <TextField
                      sx={{
                        width: "100%",
                        "& label": {
                          color: "yellow", // Label color when not focused
                        },
                        "& .MuiInputBase-input": {
                          color: "yellow", // Input text color when not focused
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "yellow", // Outline color when not focused
                        },
                        "&.Mui-focused": {
                          "& label": {
                            color: "yellow", // Label color when focused
                          },
                          "& .MuiInputBase-input": {
                            color: "yellow", // Input text color when focused
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "yellow", // Outline color when focused
                          },
                        },
                        "&.Mui-focused.MuiFilledInput": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "yellow", // Outline color when filled
                          },
                        },
                        "&.MuiFilledInput": {
                          backgroundColor: "yellow", // Background color when filled
                        },
                      }}
                      label="Account No."
                      id="acc_no"
                      value={userData.acc_no}
                      onChange={handleChange}
                      type="number"
                      variant="outlined"
                    /> */}
                  </div>
                  {/* <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      id="bank_name"
                      value={userData.bank_name}
                      onChange={handleChange}
                      label="Bank Name"
                      variant="outlined"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      value={userData.ifsc_code}
                      id="ifsc_code"
                      label="IFSC Code"
                      variant="outlined"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                      value={userData.upi_id}
                      id="upi_id"
                      label="UPI Id"
                      variant="outlined"
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <LoadingButton
                      sx={{
                        marginTop: "10px",
                        padding: "8px",
                        width: "100%",
                      }}
                      size="large"
                      onClick={handleBankDetail}
                      endIcon={<SendIcon />}
                      loading={btnLoading}
                      loadingPosition="end"
                      variant="outlined"
                    >
                      <span>SAVE</span>
                    </LoadingButton>
                  </div> */}
                          <a className="btn" style={{background:primaryBoxBgColor}} href="/bank_details">Submit</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
};

export default EditBankDetails;
