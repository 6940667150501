import React, { useEffect, useState } from "react";
import Login from "../screen/Login";
import { Navigate } from 'react-router-dom';


const Protect = ({children}) => {
  const [loginCheck, setLoginCheck] = useState(false);

  useEffect(() => {
    const checkTokenAndNavigate = async () => {
      try {
        const tokenGet = await localStorage.getItem("bigbuddy_user");
        // console.log(tokenGet, "logingtok splash");
        // dispatch(showUserDetail(tokenGet));
        if (tokenGet != null) {
          setLoginCheck(false);
          // props.navigation.navigate('DrawerNavigation');
        } else {
          setLoginCheck(true);
          // props.navigation.navigate('Login');
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    };
    checkTokenAndNavigate();
  }, []);

  return <>{!loginCheck ? children : <Navigate to="/signin" replace={true}></Navigate>}</>;
//     if (!loginCheck) {
//     return <Navigate to="/signin" replace={true}></Navigate>;
//   }
//   return children;
}


export default Protect;



// function Protected({ children }) {
//   const user = useSelector(selectLoggedInUser);

//   if (!user) {
//     return <Navigate to="/login" replace={true}></Navigate>;
//   }
//   return children;
// }

// export default Protected;

