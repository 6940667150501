import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import Header from "../components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Footer from "../components/Footer";
import BackBtn from "../components/BackBtn";
import signInLogo from "../assets/img/signInImg.png";
import { ToastContainer, toast } from "react-toastify";
import { api_url } from "../config";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";

const ResetPassword = () => {
  const { userToken } = useParams();
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [userData, setUserData] = useState({
    new_password: "",
    new_password_confirmation: "",
    token: userToken,
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  console.log("reset_token", userToken);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  };

  const handleResetPassword = async () => {
    if (
      userData.new_password === "" ||
      userData.new_password_confirmation === ""
    ) {
      setBtnLoading(false);
      notifyErr("Please fill all required fields!");
      return false;
    }
    try {
      setBtnLoading(true);
      const response = await axios.post(
        `${api_url}/auth/password/reset?${userToken}`,
        userData
      );
      console.log(response.data, "res");
      setBtnLoading(false);
      // setUserDetails(response.data);
      if (response.data.success === true) {
        notifySuccess(response.data.message);
        // navigate('/')
      }
    } catch (error) {
      setBtnLoading(false);
      if (error.response) {
        notifyErr(error.response.data.message);
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {/* <Header /> */}
      <BackBtn navigate={"/"} />
      <ToastContainer />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              {/* <div
                className="img"
                // style="background-image: url(images/bg-1.jpg);"
              ></div> */}
              <img
                src={signInLogo}
                alt="signin-image"
                style={{ height: "300px" }}
              />
              <div className="w-100 p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">Reset Password</h3>
                  </div>
                </div>
                <form action="#" className="signin-form">
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      label="Password"
                      sx={{ width: "100%" }}
                      value={userData.new_password}
                      onChange={handleChange}
                      id="new_password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", marginTop: "10px" }}
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      label="Confirm Password"
                      sx={{ width: "100%" }}
                      value={userData.new_password_confirmation}
                      onChange={handleChange}
                      id="new_password_confirmation"
                      type={showPassword2 ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            edge="end"
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <div className="form-group mt-2">
                    <LoadingButton
                      sx={{
                        padding: "8px",
                        width: "100%",
                      }}
                      size="large"
                      onClick={handleResetPassword}
                      endIcon={<SendIcon />}
                      loading={btnLoading}
                      loadingPosition="end"
                      variant="outlined"
                    >
                      <span>Submit</span>
                    </LoadingButton>
                  </div>
                  <div className="mt-3 text-center">
                    <Link className="" to="/login">
                      Go to login Page
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default ResetPassword;
