import "./App.css";
import Home from "./screen/Home";
import { useLocation } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Login from "./screen/Login";
import SignUp from "./screen/SignUp";
import Contact from "./screen/Contact";
import ProfileSection from "./screen/ProfileSection";
import EditBankDetails from "./screen/EditBankDetails";
import EditProfile from "./screen/EditProfile";
import WithdrawalMoney from "./screen/WithdrawalMoney";
import AddMoney from "./screen/AddMoney";
import Notification from "./screen/Notification";
import GiftSection from "./screen/GiftSection";
import RechargeRecord from "./components/table/RechargeRecord";
import GiftVoucher from "./screen/GiftVoucher";
import ForgetPassword from "./screen/ForgetPassword";
import ResetPassword from "./screen/ResetPassword";
import WalletRecord from "./components/table/WalletRecord";
import WithdrawalRecord from "./components/table/WithdrawalRecord";
import AboutUs from "./screen/AboutUs";
import ReferralRecord from "./components/table/ReferralRecord";
import CoinApi from "./screen/CoinApi";
import MyTeam from "./screen/MyTeam";
import Wallet from "./screen/Wallet";
import Promotion from "./screen/Promotion";
import Activity from "./screen/Activity";
import Deposit from "./screen/Deposit";
import Withdrawal from "./screen/Withdrawal";
import ComingSoon from "./screen/ComingSoon";
import Page_404 from "./screen/Page_404";
import Profile from "./screen/Profile";
import Protect from "./components/Protect";
import Gift from "./screen/Gift";
import FirstDeposit from "./screen/FirstDeposit";
import { ToastContainer } from "react-toastify";

const RouteScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};



function App() {
  return (
    <>
      <BrowserRouter>
        <RouteScrollToTop />
        <ToastContainer/>
        <Routes>
          <Route exact path="*" element={<Page_404 />} />
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/" element={<Contact/>} /> */}
          <Route exact path="/signin" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/profile_section" element={<Protect><Profile /></Protect> } />
          <Route exact path="/gift_section" element={<Protect><Gift /></Protect>} />
          <Route exact path="/bank_details" element={<EditBankDetails />} />
          <Route exact path="/edit_profile" element={<EditProfile />} />
          <Route exact path="/withdrawal_money" element={<WithdrawalMoney />} />
          <Route exact path="/add_money" element={<AddMoney />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/withdrawal_record" element={<WithdrawalRecord />} />
          <Route exact path="/recharge_record" element={<RechargeRecord />} />
          <Route exact path="/wallet_record" element={<WalletRecord />} />
          <Route exact path="/gift_voucher" element={<GiftVoucher />} />
          <Route exact path="/forget_password" element={<ForgetPassword />} />
          <Route exact path="/reset_password/:userToken" element={<ResetPassword />} />
          <Route exact path="/about_us" element={<AboutUs />} />
          <Route exact path="/contact_us" element={<Contact />} />
          <Route exact path="/referral_details" element={<ReferralRecord />} />
          <Route exact path="/coin_api" element={<CoinApi />} />
          <Route exact path="/my_team" element={<MyTeam />} />
          <Route exact path="/wallet" element={<Wallet />} />
          <Route exact path="/promotion" element={<Promotion />} />
          <Route exact path="/activity" element={<Activity />} />
          <Route exact path="/wallet" element={<Protect><Wallet /></Protect>} />
          <Route exact path="/deposit" element={<Deposit />} />
          <Route exact path="/withdrawal" element={<Withdrawal />} />
          <Route exact path="/coming_soon" element={<ComingSoon />} />
          <Route exact path="/first_deposit" element={<FirstDeposit />} />
          {/* <Header />
          <Footer /> */}
        </Routes>
        {/* <ScrollToTop smooth color="#FA4318" /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
