import React, { useState, useEffect } from "react";
import axios from "axios";

const CoinApi = () => {
  const [cryptoData, setCryptoData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = "5CBBAB65-DF2D-4A36-9D1E-F632786D9C45";
        const apiUrl = "https://rest.coinapi.io/v1/assets";

        const response = await axios.get(apiUrl, {
          headers: {
            "X-CoinAPI-Key": apiKey,
          },
        });

        setCryptoData(response.data);
        console.log("cryptocurrency data:", response.data);
      } catch (error) {
        console.error("Error fetching cryptocurrency data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div>
        
      </div>
      <h1>Cryptocurrency List</h1>
      <ul>
        {cryptoData.map((crypto) => (
          <li key={crypto.id}>{crypto.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default CoinApi;
