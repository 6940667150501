import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Footer from "../components/Footer";
import BackBtn from "../components/BackBtn";
import signInLogo from "../assets/img/signInImg.png";
import { ToastContainer, toast } from "react-toastify";
import {
  api_url,
  displayWidth,
  primaryBoxBgColor,
  primaryColor,
} from "../config";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import { requirePropFactory } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
    // console.log(value);

    // if (fieldName === 'phone') {
    //   const remainingDigits = 10 - value.length;
    //   if (value.length === 10 && /^\d+$/.test(value)) {
    //     // setBtnDisable(true)
    //     setPhoneHelpText('');
    //   } else {
    //     // setBtnDisable(false)
    //     setPhoneHelpText(`Remaining digits: ${remainingDigits}`);
    //   }
    // } else if (fieldName === 'email') {
    //   // Basic email validation using a regular expression
    //   if (/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value)) {
    //     setEmailHelpText('');
    //     // setBtnDisable(false)
    //   } else {
    //     setEmailHelpText('Please enter a valid email address');
    //     // setBtnDisable(true)
    //   }
    // }
    // const isPhoneValid = userData.phone.length === 10;
    // const isEmailValid = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(
    //   userData.email,
    // );
    // setBtnDisable(!(isPhoneValid && isEmailValid));
  };

  const handleLogin = async () => {
    if (userData.username === "" || userData.password === "") {
      console.log("first");
      setBtnLoading(false);
      notifyErr("Please fill all required fields!");
      return false;
    }
    try {
      setBtnLoading(true);
      const response = await axios.post(`${api_url}/auth/login`, userData);
      console.log(response.data, "res");
      setBtnLoading(false);
      // setUserDetails(response.data);
      if (response.data.success === true) {
        notifySuccess(response.data.message);
        localStorage.setItem("bigbuddy_user", response.data.data.token);
        navigate("/profile_section");
      }
    } catch (error) {
      setBtnLoading(false);
      if (error.response) {
        notifyErr(error.response.data.message);
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {/* <Header /> */}
      <BackBtn title={"Sign In"} />
      <ToastContainer />
      <div
        className="container "
        style={{ marginTop: "100px", height: "85vh" }}
      >
        <div className="row justify-content-center">
          <div className="">
            <div className="" style={{ width: displayWidth }}>
              <div className="text-center" style={{}}>
                <Link to="/">
                  <img
                    src={require("../assets/img/logo/riv_logo.png")}
                    alt="signin-image"
                    style={{ height: "160px", borderRadius: "10px" }}
                  />
                </Link>
              </div>
              <div className="w-100 p-4 p-md-5">
                {/* <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">Sign In</h3>
                  </div>
                </div> */}
                <div className="signin-form">
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      class="form-control text-light customInput"
                      placeholder="Username"
                      id="username"
                      value={userData.username}
                      onChange={handleChange}
                      style={{ background: "#333", color: "white" }}
                    />
                    {/* <TextField
                      sx={{ width: "100%"}}
                      label="Email or Mobile No."
                      variant="outlined"
                      id="username"
                      value={userData.username}
                      onChange={handleChange}
                    /> */}
                  </div>
                  <div className="form-group mb-3">
                    <div class="input-group mb-3">
                      <input
                        type={showPassword ? "text" : "password"}
                        class="form-control customInput"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon2"
                        value={userData.password}
                        onChange={handleChange}
                        id="password"
                      />
                      <span
                        class="input-group-text"
                        id="basic-addon2"
                        onClick={handleClickShowPassword}
                        style={{ background: "#333", color: primaryColor }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </span>
                    </div>
                    {/* <TextField
                      sx={{ width: "100%" }}
                      type="password"
                      id="password"
                      label="Password"
                      variant="outlined"
                      value={userData.password}
                      onChange={handleChange}
                      
                    /> */}
                    {/* <FormControl sx={{ width: "100%" }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        label="Password"
                        sx={{ width: "100%" }}
                        value={userData.password}
                        onChange={handleChange}
                        id="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl> */}
                    <button
                      className="btn w-100"
                      style={{ background: primaryBoxBgColor }}
                      onClick={handleLogin}
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="form-group">
                    {/* <LoadingButton
                      sx={{
                        padding: "8px",
                        width: "100%",
                      }}
                      size="large"
                      onClick={handleLogin}
                      endIcon={<SendIcon />}
                      loading={btnLoading}
                      loadingPosition="end"
                      variant="outlined"
                    >
                      <span>SIGN IN</span>
                    </LoadingButton> */}
                    {/* <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3"
                     >
                      Sign In
                    </button> */}
                  </div>
                  <div
                    className="form-group d-flex mt-4"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="text-left text-light">
                      Not a member?{" "}
                      <Link style={{ color: primaryColor }} to="/signup">
                        Sign Up
                      </Link>
                    </div>
                    <div className="text-right">
                      <Link
                        style={{ color: primaryColor }}
                        to="/forget_password"
                      >
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default Login;
