import { createTheme } from "@mui/material/styles";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
export const theme = createTheme({
  palette: {
    main_color: createColor("#fa4318"),
    errortext: createColor("#ff0f0f"),
  },
});


export const api_url = "https://bigbuddy.asyscraft.com/backend/public/api";

export const loginToken = localStorage.getItem("bigbuddy_user");

export  const primaryBoxBgColor =
"linear-gradient(180deg, rgba(217,172,79,1) 35%, rgba(255,208,112,1) 100%)";
export  const primaryColor = "#d9ac4f";
export  const primaryColor2 = "rgb(98, 65, 0)";


export  const displayWidth = "425px";
