import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FooterTwo from "../components/FooterTwo";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import { Grid, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { primaryBoxBgColor, primaryColor } from "../config";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";

const Promotion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loadingBar } = useSelector((state) => state.user);

  return (
    <>
      {loadingBar ? <Spinner loading={loadingBar} /> : null}
      <Header />
      <div style={{marginTop:"100px"}}>
        <div className="promoCon">
          <div
            style={{
              textAlign: "center",
              background: primaryBoxBgColor,
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{ color: "#624100", fontSize: "27px", fontWeight: "bold" }}
            >
              0
            </div>
            <div
              style={{
                background: "#624100",
                padding: "10px",
                textAlign: "center",
                margin: "10px",
                borderRadius: "10px",
                fontWeight: "bold",
                color: primaryColor,
              }}
            >
              Yesterday's total commission
            </div>
            <div style={{ color: "#624100" }}>
              Upgrade the level to increase commission income
            </div>
          </div>
          <div>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container sx={{ padding: "10px" }}>
                <Grid item xs={6}>
                  <div
                    className="promoHead"
                    style={{ borderRadius: "10px 0px 0px 0px" }}
                  >
                    <PersonIcon /> Direct subordinates
                  </div>
                  <div
                    className="promoBody"
                    style={{ borderRadius: "0px 0px 0px 10px" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Number of register</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Deposit number</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Deposit amount</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Number of people making first deposit</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className="promoHead"
                    style={{ borderRadius: "0px 10px 0px 0px" }}
                  >
                    <PersonIcon /> Direct subordinates
                  </div>
                  <div
                    className="promoBody"
                    style={{ borderRadius: "0px 0px 10px 0px" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Number of register</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Deposit number</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Deposit amount</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Number of people making first deposit</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div
              style={{
                background: primaryBoxBgColor,
                padding: "10px",
                textAlign: "center",
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              Invitaion Link
            </div>

            <div>
              <div className="promoTab">
                <div className="promoTabSub">
                  <ContentCopyIcon style={{ color: primaryColor }} /> Copy
                  invitaion code{" "}
                </div>
                <div>
                  <span style={{ color: primaryColor }}>121223243</span>
                  <ArrowForwardIosIcon />
                </div>
              </div>
              <div className="promoTab">
                <div className="promoTabSub">
                  <ArticleOutlinedIcon style={{ color: primaryColor }} />{" "}
                  Subordinate data
                </div>
                <div>
                  <ArrowForwardIosIcon />
                </div>
              </div>
              <div className="promoTab">
                <div className="promoTabSub">
                  <LocalAtmOutlinedIcon style={{ color: primaryColor }} />{" "}
                  Commission details
                </div>
                <div>
                  <ArrowForwardIosIcon />
                </div>
              </div>
              <div className="promoTab">
                <div className="promoTabSub">
                  <AssignmentOutlinedIcon style={{ color: primaryColor }} />{" "}
                  Invition rules
                </div>
                <div>
                  <ArrowForwardIosIcon />
                </div>
              </div>
              <div className="promoTab">
                <div className="promoTabSub">
                  <SupportAgentOutlinedIcon style={{ color: primaryColor }} />{" "}
                  Agent line customer service
                </div>
                <div>
                  <ArrowForwardIosIcon />
                </div>
              </div>
              <div className="promoTab">
                <div className="promoTabSub">
                  <RequestQuoteOutlinedIcon style={{ color: primaryColor }} />{" "}
                  Rebate ratio
                </div>
                <div>
                  <ArrowForwardIosIcon />
                </div>
              </div>
            </div>

            <Box sx={{ flexGrow: 1 }}>
              <hr color="white" />
              <h5 style={{ color: primaryColor, margin: "10px" }}>
                Promotion data
              </h5>
              <Grid container sx={{ padding: "10px", background: "#3F3F3F" }}>
                <Grid item xs={6}>
                  <div
                    className="promoBody "
                    style={{
                      borderRadius: "0px 0px 0px 10px",
                      background: "unset",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>This week</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Direct subordinate</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    className="promoBody"
                    style={{
                      borderRadius: "0px 0px 10px 0px",
                      background: "unset",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Total commission</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>0</div>
                      <div>Total number of subordinates in the team</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>

      <FooterTwo value={2} />
    </>
  );
};

export default Promotion;
