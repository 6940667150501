import React, { useState, useEffect } from "react";
import { api_url } from "../config";
import axios from "axios";
import BackBtn from "../components/BackBtn";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Spinner from "../components/Spinner";

const AboutUs = () => {
  const [notifyData, setNotifyData] = useState([""]);
  const [loading, setLoading] = useState(false);

  async function fetchNotifyData() {
    try {
      setLoading(true);
      const tokenGet = await localStorage.getItem("logintoken");
      const response = await axios.get(`${api_url}/content`, {
        headers: {
          Authorization: `Bearer ${tokenGet}`,
        },
      });
      console.log("notify", response.data);
      setNotifyData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  }
  useEffect(() => {
    fetchNotifyData();
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      {loading ? <Spinner loading={loading} /> : null}
      <BackBtn />
      <Container maxWidth="lg">
        <h1 className="text-center m-3">About Us</h1>
        {/* {notifyData.map((item) => {
          const inputDate = item.created_at;
          const date = new Date(inputDate);
          const day = date.getDate();
          const month = date.getMonth() + 1; // Months are zero-indexed
          const year = date.getFullYear();
          const options = {
            hour: "2-digit",
            minute: "2-digit",
          };

          const formattedTime = date.toLocaleTimeString("en-US", options);
          // const formattedDate = date.toLocaleString("en-US", options);
          const formattedDate = `${day}-${month}-${year} , ${formattedTime} `;

          return ( */}
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <p className="py-2">{notifyData.conclusion}</p>
          <p className="py-2">{notifyData.content}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <p className="py-2">{notifyData.content}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h4 className="py-2">{notifyData?.sub_title1?.title}</h4>
          <h6 className="py-2">{notifyData?.sub_title1?.sub_title1}</h6>
          <p className="py-2">{notifyData?.sub_title1?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title1?.sub_title2}</h6>
          <p className="py-2">{notifyData?.sub_title1?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h4 className="py-2">{notifyData?.sub_title2?.title}</h4>
          <h6 className="py-2">{notifyData?.sub_title2?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title2?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title2?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title2?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h4 className="py-2">{notifyData?.sub_title3?.title}</h4>
          <h6 className="py-2">{notifyData?.sub_title3?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title3?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title3?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title3?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h4 className="py-2">{notifyData?.sub_title4?.title}</h4>
           <p className="py-2">{notifyData?.sub_title4?.content}</p>
          <h6 className="py-2">{notifyData?.sub_title4?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title4?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title4?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title4?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h4 className="py-2">{notifyData?.sub_title5?.title}</h4>
          <h6 className="py-2">{notifyData?.sub_title5?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title5?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title5?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title5?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h4 className="py-2">{notifyData?.sub_title5?.title}</h4>
          <h6 className="py-2">{notifyData?.sub_title5?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title5?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title5?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title5?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h4 className="py-2">Privacy Policy</h4>
          <h6 className="py-2">{notifyData?.sub_title6?.title}</h6>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title1}</h5>
           <p className="py-2">{notifyData?.sub_title6?.content1}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title2?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title2?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title2?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title2?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title2?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title3?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title3?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title3?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title3?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title3?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title4?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title4?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title4?.content1}</p>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title4?.sub_title2}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title4?.content2}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title5?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title5?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title5?.content1}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title6?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title6?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title6?.content1}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title7?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title7?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title7?.content1}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title8?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title8?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title8?.content1}</p>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
          <h5 className="py-2">{notifyData?.sub_title6?.sub_title9?.title}</h5>
          <h6 className="py-2">{notifyData?.sub_title6?.sub_title9?.sub_title1}</h6>
           <p className="py-2">{notifyData?.sub_title6?.sub_title9?.content1}</p>
        </Box>
        {/* );
        })} */}
      </Container>
    </>
  );
};

export default AboutUs;
