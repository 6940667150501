import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FirstDepositContent from "./FirstDepositContent";
import { IoCloseCircleOutline } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  p: 4,
};

export default function OfferModal() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            class="card"
            style={{
              background: "#333",
              color: "white",
              padding: "13px",
              overflowY: "scroll",
              height: "600px",
            }}
          >
            <FirstDepositContent />
          </div>
          <div className="text-center" style={{}} onClick={handleClose}>
            <IoCloseCircleOutline
              style={{ fontSize: "60px", color: "white" }}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
