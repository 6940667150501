import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Footer from "../components/Footer";
import BackBtn from "../components/BackBtn";
import signInLogo from "../assets/img/signInImg.png";
import { ToastContainer, toast } from "react-toastify";
import { api_url } from "../config";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
    // console.log(value);

    // if (fieldName === 'phone') {
    //   const remainingDigits = 10 - value.length;
    //   if (value.length === 10 && /^\d+$/.test(value)) {
    //     // setBtnDisable(true)
    //     setPhoneHelpText('');
    //   } else {
    //     // setBtnDisable(false)
    //     setPhoneHelpText(`Remaining digits: ${remainingDigits}`);
    //   }
    // } else if (fieldName === 'email') {
    //   // Basic email validation using a regular expression
    //   if (/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value)) {
    //     setEmailHelpText('');
    //     // setBtnDisable(false)
    //   } else {
    //     setEmailHelpText('Please enter a valid email address');
    //     // setBtnDisable(true)
    //   }
    // }
    // const isPhoneValid = userData.phone.length === 10;
    // const isEmailValid = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(
    //   userData.email,
    // );
    // setBtnDisable(!(isPhoneValid && isEmailValid));
  };

  const handleForgetPassword = async () => {
    if (userData.email === "") {
      setBtnLoading(false);
      notifyErr("Please fill all required fields!");
      return false;
    }
    try {
      setBtnLoading(true);
      const tokenGet = localStorage.getItem("logintoken");
      // console.log('forget'  ,`${api_url}/auth/forget_password?${tokenGet}` )
      const response = await axios.post(
        `${api_url}/auth/password/forgot`,
        userData
      );
      // console.log(response.data, "res");
      setBtnLoading(false);
      // setUserDetails(response.data);
      if (response.data.success === true) {
        notifySuccess(response.data.message);
      }
    } catch (error) {
      setBtnLoading(false);
      if (error.response) {
        notifyErr(error.response.data.message);
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {/* <Header /> */}
      <BackBtn navigate={"/"} />
      <ToastContainer />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              {/* <div
                className="img"
                // style="background-image: url(images/bg-1.jpg);"
              ></div> */}
              <img
                src={signInLogo}
                alt="signin-image"
                style={{ height: "300px" }}
              />
              <div className="w-100 p-4 p-md-5">
                <div className="d-flex">
                  <div className="w-100">
                    <h3 className="mb-4">Forgot Password</h3>
                  </div>
                </div>
                <form action="#" className="signin-form">
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      id="email"
                      label="Email or Mobile No."
                      variant="outlined"
                      value={userData.email}
                      onChange={handleChange}
                      type="email"
                    />
                  </div>
                  <div className="form-group">
                    <LoadingButton
                      sx={{
                        padding: "8px",
                        width: "100%",
                      }}
                      size="large"
                      onClick={handleForgetPassword}
                      endIcon={<SendIcon />}
                      loading={btnLoading}
                      loadingPosition="end"
                      variant="outlined"
                    >
                      <span>Submit</span>
                    </LoadingButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default ForgetPassword;
