import React, { useEffect, useState } from "react";
import { FaHistory, FaStopwatch, FaTrophy } from "react-icons/fa";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import PriceModal from "../../components/PriceModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  api_url,
  primaryBoxBgColor,
  primaryColor,
  primaryColor2,
} from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { showUserDetail } from "../../redux/stateSlice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { GoDotFill } from "react-icons/go";
import Winner from "../../components/table/Winner";
import { Typography } from "@mui/material";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import Grid from "@mui/material/Grid";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const FiveMinGame = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectvalue, setselectvalue] = useState("");
  const [selectvalue2, setselectvalue2] = useState("0");
  const [bidNo, setBidNo] = useState(null);
  const [previousBidNo, setPreviousBidNo] = useState(null);
  const [countSec, setCountSec] = useState(0);
  const [countMin, setCountMin] = useState(0);
  const [countdownText, setCountdownText] = useState("");
  const [typeOfBid, setTypeOfBid] = useState("");
  const [colorType, setColorType] = useState("");
  const [bidBtnDisable, setBidBtnDisable] = useState(false);
  const [loginToken, setLoginToken] = useState("");
  const [winnerData, setWinnerData] = useState([""]);
  const [winnerRecord, setWinnerRecord] = useState([""]);
  const [selectQuantity, setSelectQuantity] = useState(1);
  const [value, setValue] = React.useState(0);
  const [timerDis, setTimerDis] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = (e) => {
    setOpen(true);
    if (
      e.target.value === "joinred" ||
      e.target.value === "joinviolet" ||
      e.target.value === "joingreen"
    ) {
      setTypeOfBid("1");
    } else {
      setTypeOfBid("2");
    }
    setColorType(
      e.target.value === "joinred"
        ? 1
        : e.target.value === "joinviolet"
        ? 2
        : e.target.value === "joingreen"
        ? 3
        : 0
    );
    console.log(e.target.value, "cat value");
    setselectvalue(e.target.value);
    if (
      e.target.value === "joingreen" ||
      e.target.value === "joinred" ||
      e.target.value === "joinviolet"
    ) {
      setselectvalue2("0");
    } else {
      setselectvalue2(e.target.value);
    }
  };

  const handleClose = (newQuantity) => {
    setOpen(false);
    setQuantity(1);
    if (newQuantity !== undefined) {
      setQuantity(newQuantity);
    }
  };

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  useEffect(() => {
    const checkTokenAndNavigate = async () => {
      try {
        const tokenGet = await localStorage.getItem("logintoken");
        console.log(tokenGet, "logingtok");
        // dispatch(showUserDetail(tokenGet));
        if (tokenGet != null) {
          setLoginToken(tokenGet);
          // setLoginCheck(false);
          // props.navigation.navigate('DrawerNavigation');
        } else {
          // setLoginCheck(true);
          // props.navigation.navigate('Login');
        }
      } catch (error) {
        console.error("Error checking token:", error);
      }
    };
    checkTokenAndNavigate();
  }, []);

  const handleBidBtn = async (selectedValue) => {
    console.log(`typeOfBid: ${typeOfBid}`);
    // console.log(`selectedValue: ${selectedValue}`);

    try {
      // setModalVisible(false);
      // setLoading(true);
      setOpen(false);
      const response = await axios.post(
        `${api_url}/bids/bid_request`,
        {
          bid_no: bidNo,
          qty: "1",
          total_amount: selectedValue,
          bid_type: typeOfBid, //1=>color, 2=>number
          color: colorType, // if number then colortype is 0
          number: selectvalue2,
        },
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );
      console.log(response.data, "res");
      // setLoading(false);
      // setUserDetails(response.data);
      if (response.data.success === true) {
        setOpen(false);
        setQuantity(1);
        notifySuccess(response.data.message);
        // Toast.show(response.data.message);
        // setModalVisible(false);
        dispatch(showUserDetail());
      }
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        // Toast.show(error.response.data.message);
        setOpen(false);
        notifyErr(error.response.data.message);
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const bidComplete = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(`${api_url}/bids/bid_complete`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
        params: { bid_no: bidNo },
      });
      console.log(response.data, "res");
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        console.error("Server Error bid-com:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const getWinnerData = async () => {
    const tokenGet = await localStorage.getItem("logintoken");
    try {
      // setLoading(true);
      const response = await axios.get(`${api_url}/transaction/winners`, {
        headers: {
          Authorization: `Bearer ${tokenGet}`,
        },
      });
      console.log("res-win-data", response.data);
      setRows2(response?.data?.data);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const getWinnerRecord = async () => {
    const tokenGet = await localStorage.getItem("logintoken");
    try {
      // setLoading(true);
      const response = await axios.get(
        `${api_url}/transaction/winning_record`,
        {
          headers: {
            Authorization: `Bearer ${tokenGet}`,
          },
        }
      );
      console.log("res-win-record", response.data);
      setRows(response?.data?.data?.reverse());
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        console.error("Server Error:res-win-record", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const getWinnerAmount = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(`${api_url}/bids/get_winning_amount`, {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      });
      console.log("res-win-amount", response.data);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getRandomNumberFromDateAndTime() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0"); // Get the day (zero-padded)
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Get the month (zero-padded)
    const year = currentDate.getFullYear().toString(); // Get the last 2 digits of the year
    const hours = currentDate.getHours().toString().padStart(2, "0"); // Get the hours (zero-padded)
    const second = Math.floor(currentDate.getSeconds()); // Round second to the nearest multiple of 3
    const minutes = Math.floor(currentDate.getMinutes() / 3) * 3; // Round minutes to the nearest multiple of 3
    const minutesView = Math.floor(currentDate.getMinutes()); // Round minutes to the nearest multiple of 3
    const minutesStr = minutes.toString().padStart(2, "0"); // Get the minutes (zero-padded)

    const randomNumber = day + month + year + hours + minutesStr;
    // console.log(randomNumber);

    setCountSec(60 - currentDate.getSeconds());

    const remainder = currentDate.getMinutes() % 5;
    if (remainder >= 0 && remainder <= 4) {
      setCountMin(4 - remainder);
    }

    return parseInt(randomNumber, 10);
  }

  function checkRandomNumberChange() {
    const newRandomNo = getRandomNumberFromDateAndTime();
    setPreviousBidNo(bidNo); // Update the previous random number
    setBidNo(newRandomNo); // Update the current random number
  }

  // Use useEffect to periodically check for changes in the random number
  useEffect(() => {
    const intervalId = setInterval(checkRandomNumberChange, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [bidNo]);

  useEffect(() => {
    const countText1 = `${countMin < 10 ? `0${countMin}` : countMin}:${
      countSec < 10 ? `0${countSec}` : countSec
    }`;
    // const countText1 = `${countMin}:${countSec}`;
    setCountdownText(countText1);
    // console.log(countdownText);
    if (
      // countText1 === "00:24" ||
      // countText1 === "00:23" ||
      // countText1 === "00:22" ||
      // countText1 === "00:21" ||
      // countText1 === "00:20" ||
      // countText1 === "00:19" ||
      // countText1 === "00:18" ||
      // countText1 === "00:17" ||
      // countText1 === "00:16" ||
      // countText1 === "00:15" ||
      // countText1 === "00:14" ||
      // countText1 === "00:13" ||
      // countText1 === "00:12" ||
      // countText1 === "00:11" ||
      countText1 === "00:10" ||
      countText1 === "00:09" ||
      countText1 === "00:08" ||
      countText1 === "00:07" ||
      countText1 === "00:06" ||
      countText1 === "00:05" ||
      countText1 === "00:04" ||
      countText1 === "00:03" ||
      countText1 === "00:02" ||
      countText1 === "00:01"
      ) {
        setBidBtnDisable(true);
        setTimerDis(true)
      } else {
        setTimerDis(false)
        setBidBtnDisable(false);
      }
    if (countText1 === "00:25") {
      setOpen(false);
      setBidBtnDisable(true);
      getWinnerAmount();
    }
    if (countText1 === "00:01") {
      setBidBtnDisable(false);
      getWinnerData();
      getWinnerRecord();
      console.log("cmplt");
      // Toast.show('Win Record Update');
      bidComplete();
      // checkRandomNumberChange()
    }
  }, [countMin, countSec]);

  useEffect(() => {
    getWinnerRecord();
    getWinnerData();
  }, []);

  // useEffect(() => {
  //   const countText1 = `${countMin}:${countSec}`;
  //   const handleBeforeUnload = (event) => {
  //     console.log("hit-0:1")
  //     if (countText1 === "0:1") {
  //       bidComplete();
  //     }
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [countSec]);

  // useEffect(() => {
  //   dispatch(showUserDetail())
  //   getWinnerData();
  // }, [user]);

  return (
    <>
      <ToastContainer />
      <div className="">
        {/* <h1>Product Quantity: {quantity}</h1>
      <button onClick={handleOpen}>Adjust Quantity</button> */}
        <PriceModal
          open={open}
          handleClose={handleClose}
          handleBidBtn={handleBidBtn}
          value={selectvalue}
          initialQuantity={quantity}
        />
        <div className="p-2">
          <div className="periodCon d-flex justify-content-between">
            <div className="p-2">
              <div
                className="btn "
                style={{
                  background: primaryColor,
                  color: primaryColor2,
                  border: `1px solid ${primaryColor2}`,
                  fontSize: "15px",
                }}
              >
                <AssignmentOutlinedIcon style={{ fontSize: "15px" }} /> How to
                play
              </div>
              <div className="my-2" style={{ color: primaryColor2 }}>
                Win go 1 Min
              </div>
              <div className="d-flex selectImg2">
                <img
                  className=""
                  src={require("../../assets/img/icons/0.png")}
                  alt=""
                />
                <img
                  className=""
                  src={require("../../assets/img/icons/1.png")}
                  alt=""
                />
                <img
                  className=""
                  src={require("../../assets/img/icons/2.png")}
                  alt=""
                />
                <img
                  className=""
                  src={require("../../assets/img/icons/3.png")}
                  alt=""
                />
                <img
                  className=""
                  src={require("../../assets/img/icons/4.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="p-2">
              <p
                style={{
                  color: primaryColor2,
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Time remaining
              </p>
              <div className="" style={{ flexDirection: "" }}>
                <div className="d-flex">
                  <div className="timeNo">
                    {countMin < 10 ? `0${countMin}` : countMin}
                  </div>
                  <div className="timerDots">:</div>
                  <div className="timeNo">
                    {countSec < 10 ? `0${countSec}` : countSec}
                  </div>
                </div>
                <p
                  style={{
                    color: primaryColor2,
                    fontWeight: "bold",
                    fontSize: "20px",
                    margin: "10px 0px 0px 0px",
                  }}
                >
                  {bidNo ? bidNo : "--"}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div>
            <p>
              <FaTrophy /> Period
            </p>
            <p className="timeNo">{bidNo}</p>
          </div>
          <div>
            <p>
              <FaStopwatch /> Countdown
            </p>
            <p>
              <div className="d-flex" style={{ flexDirection: "" }}>
                <div className="timeNo">
                  {countMin < 10 ? `0${countMin}` : countMin}
                </div>
                <div className="timerDots">:</div>
                <div className="timeNo">
                  {countSec < 10 ? `0${countSec}` : countSec}
                </div>
              </div>
            </p>
          </div>
        </div> */}

{timerDis ? (
          <div
            className="p-2 py-3 my-2 timerbg"
            style={{
              borderRadius: "10px",
              marginRight: "6px",
            }}
          >
            <div class="timerbg__image"></div>
            <h1>0</h1>
            <h1>{countSec}</h1>
          </div>
        ) : (
          <div
            className="p-2 py-3 my-2"
            style={{
              background: "rgb(33 33 33)",
              borderRadius: "10px",
              marginRight: "6px",
            }}
          >
            <div className="d-flex" style={{ justifyContent: "space-around" }}>
              <button
                className="btn"
                value="joingreen"
                onClick={(e) => handleOpen(e.target.value)}
                name="Join Green"
                style={{
                  backgroundColor: bidBtnDisable === false ? "green" : "gray",
                  color: "white",
                  boxShadow:
                    "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
                }}
                disabled={bidBtnDisable}
              >
                Join Green
              </button>
              <button
                className="btn"
                value="joinviolet"
                onClick={(e) => handleOpen(e.target.value)}
                style={{
                  backgroundColor: bidBtnDisable === false ? "#b659fe" : "gray",
                  color: "white",
                  boxShadow:
                    "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
                }}
                disabled={bidBtnDisable}
              >
                Join Violet
              </button>
              <button
                className="btn"
                value="joinred"
                onClick={(e) => handleOpen(e.target.value)}
                style={{
                  backgroundColor: bidBtnDisable === false ? "red" : "gray",
                  color: "white",
                  boxShadow:
                    "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
                }}
                disabled={bidBtnDisable}
              >
                Join Red
              </button>
            </div>

            <div
              className="d-flex mt-3"
              style={{ justifyContent: "space-around" }}
            >
              {/* <button
              className="btn"
              onClick={(e) => console.log(e.currentTarget.value)}
            > */}
              <img
                className="selectImg"
                src={require("../../assets/img/icons/0.png")}
                alt="0"
                data-value="1"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              {/* </button> */}
              <img
                className="selectImg"
                src={require("../../assets/img/icons/1.png")}
                alt="1"
                data-value="1"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              <img
                className="selectImg"
                src={require("../../assets/img/icons/2.png")}
                alt=""
                data-value="2"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              <img
                className="selectImg"
                src={require("../../assets/img/icons/3.png")}
                alt=""
                data-value="2"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              <img
                className="selectImg"
                src={require("../../assets/img/icons/4.png")}
                alt=""
                data-value="4"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />

              {/* <button
            className="btn "
            value="1"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            1
          </button>
          <button
            className="btn"
            value="2"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            2
          </button>
          <button
            className="btn"
            value="3"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            3
          </button>
          <button
            className="btn"
            value="4"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            4
          </button>
          <button
            className="btn"
            value="5"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            5
          </button> */}
            </div>
            <div
              className="d-flex mt-3"
              style={{ justifyContent: "space-around" }}
            >
              <img
                className="selectImg"
                src={require("../../assets/img/icons/5.png")}
                alt=""
                data-value="5"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              <img
                className="selectImg"
                src={require("../../assets/img/icons/6.png")}
                alt=""
                data-value="6"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              <img
                className="selectImg"
                src={require("../../assets/img/icons/7.png")}
                alt=""
                data-value="7"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              <img
                className="selectImg"
                src={require("../../assets/img/icons/8.png")}
                alt=""
                data-value="8"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              <img
                className="selectImg"
                src={require("../../assets/img/icons/9.png")}
                alt=""
                data-value="9"
                onClick={(e) =>
                  handleOpen(e.currentTarget.getAttribute("data-value"))
                }
              />
              {/* <button
            className="btn"
            value="6"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            6
          </button>
          <button
            className="btn"
            value="7"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            7
          </button>
          <button
            className="btn"
            value="8"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            8
          </button>
          <button
            className="btn"
            value="9"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            9
          </button>
          <button
            className="btn"
            value="10"
            onClick={handleOpen}
            style={{
              backgroundColor: bidBtnDisable === false ? "blue" : "gray",
              color: "white",
              boxShadow:
                "0 4px 8px rgb(0 0 0 / 31%), 0 1px 1px rgba(0, 0, 0, 0.22)",
            }}
            disabled={bidBtnDisable}
          >
            10
          </button> */}
            </div>
          </div>
        )}

        <Box
          sx={{
            width: "100%",
            marginTop: "10px",
            paddingLeft: "10px!important",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="main_color"
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .Mui-selected": {
                  color: "#333!important",
                  background: primaryBoxBgColor,
                  borderRadius: "10px",
                },
                "& .MuiTab-root": {
                  color: "gray",
                  fontWeight: "bold",
                },
              }}
            >
              <Tab
                sx={{ "&.Mui-selected": { color: "blue" } }}
                label="Game history"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ "&.Mui-selected": { color: "blue" } }}
                label="Chart"
                {...a11yProps(1)}
              />
              <Tab
                sx={{ "&.Mui-selected": { color: "blue" } }}
                label="My history"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className="table mt-3">
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  // sx={{ minWidth: 500 }}
                  aria-label="custom pagination table sticky table"
                >
                  <TableHead>
                    <TableRow sx={{}}>
                      <TableCell
                        sx={{
                          zIndex: "0",
                          backgroundColor: "#6F6F6F!important",
                          color: "white",
                        }}
                        align="left"
                      >
                        Period
                      </TableCell>
                      <TableCell
                        sx={{
                          zIndex: "0",
                          backgroundColor: "#6F6F6F!important",
                          color: "white",
                        }}
                        align="right"
                      >
                        Number
                      </TableCell>
                      <TableCell
                        sx={{
                          zIndex: "0",
                          backgroundColor: "#6F6F6F!important",
                          color: "white",
                        }}
                        align="right"
                      >
                        Color
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#3f3f3f!important",
                      color: "white!important",
                    }}
                  >
                    {rows.length === 0 ? (
                      <TableRow>
                        <TableCell
                          sx={{ color: "white!important" }}
                          colSpan={4}
                          align="center"
                        >
                          No data found
                        </TableCell>
                      </TableRow>
                    ) : (
                      (rowsPerPage > 0
                        ? rows.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : rows
                      ).map((row) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {row.bid_no}
                            </TableCell>
                            <TableCell style={{}} align="right">
                              {row.number}
                            </TableCell>
                            <TableCell style={{}} align="right">
                              <GoDotFill
                                size={23}
                                color={
                                  row.color === 3
                                    ? "green"
                                    : row.color === 2
                                    ? "violet"
                                    : "red"
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter
                    sx={{
                      backgroundColor: "#3f3f3f!important",
                      color: "white!important",
                    }}
                  >
                    <TableRow>
                      <TablePagination
                        sx={{ color: "white!important" }}
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            Coming Soon
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="table mt-3">
              <Winner data={rows2} />
            </div>
          </CustomTabPanel>
        </Box>

        <div>
          {/* <div className="text-center my-3">
            <FaHistory /> Recent History
          </div> */}
        </div>
      </div>
    </>
  );
};

export default FiveMinGame;
