import React, { useState, useEffect } from "react";
import "../assets/css/login.css";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import UpdateIcon from '@mui/icons-material/Update';
import { Link, useNavigate } from "react-router-dom";
import BackBtn from "../components/BackBtn";
import { ToastContainer, toast } from "react-toastify";
import { api_url } from "../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  showUserDetail,
  updateUserProfile,
} from "../redux/stateSlice/authSlice";

const WithdrawalMoney = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  const [btnLoading, setBtnLoading] = useState(false);

  const [userData, setUserData] = useState({
    amount: "",
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleWithdrawalRequest = async () => {
    if ((userData.amount === "")) {
      notifyErr("Fill in all the fields!");
      return false;
    }
    try {
      setBtnLoading(true);
      console.log("userdata", userData);
      const loginToken = localStorage.getItem("logintoken");
      const response = await axios.post(
        `${api_url}/request/withdrawal`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        }
      );
      console.log(response.data, "res");
      setBtnLoading(false);
      // setUserDetails(response.data);
      if (response.data.success === true) {
        notifySuccess(response.data.message);
        dispatch(showUserDetail());
      }
    } catch (error) {
      notifyErr(error.response.data.message);
      setBtnLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        // setUserDetails(error.response.data);
      } else if (error.request) {
        console.error("No response received.");
      } else {
        console.error("Request failed:", error.message);
      }
    }
  };

  const notifySuccess = (msg) => toast.success(msg);
  const notifyErr = (msg) => toast.error(msg);

  const dataToSend = {
    title: "Withdrawal Request",
    api_endpoint: "/request/withdrawal",
  };

  useEffect(() => {
    dispatch(showUserDetail());
  }, []);

  return (
    <>
      {/* <Header /> */}
      <BackBtn />
      <ToastContainer />
      <div className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              <div className="w-100 p-4 p-md-5">
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <div className="w-100 text-center">
                    <p className="bold m-0">Total Balance</p>
                    <p className="bold" style={{ fontSize: "27px" }}>
                      ₹{user?.data?.wallet}
                    </p>
                  </div>
                </div>
                <form className="signin-form">
                  <div className="form-group mb-3">
                    <TextField
                      sx={{ width: "100%" }}
                      id="amount"
                      name="amount"
                      label="Amount"
                      value={userData.amount}
                      onChange={handleChange}
                      variant="outlined"
                      type="number"
                    />
                  </div>
                  <LoadingButton
                    sx={{
                      marginTop: "10px",
                      padding: "8px",
                      width: "100%",
                    }}
                    size="large"
                    onClick={handleWithdrawalRequest}
                    endIcon={<SendIcon />}
                    loading={btnLoading}
                    loadingPosition="end"
                    variant="outlined"
                  >
                    <span>Withdrawal</span>
                  </LoadingButton>
                </form>
                <div style={{marginTop:"30px", textAlign:"center"}}>
                  <Link to={"/withdrawal_record"}><UpdateIcon/>{" "} View History </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  );
};

export default WithdrawalMoney;
